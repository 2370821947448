import React from 'react';
import styles from './creativeList.module.scss';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CreativeType, creativeTypeOrder } from 'core/creative/Creative';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { CreativeListProps } from './CreativeListModel';
import { Modal } from 'components/common/Modal/Modal';
import { SearchBar } from 'components/common/SearchBar';
import { Permission } from 'core/auth/Permission';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import classNames from 'classnames/bind';
import { ListEditBar } from 'components/ListEditBar/ListEditBar';
import { camelCase, compact } from 'lodash';

export class CreativeList extends React.Component<CreativeListProps> {

  handler: number;
  searchBarRef: any;
  cssClassNames: any;

  constructor (props) {
    super(props);
    this.cssClassNames = classNames.bind(styles);
    this.searchBarRef = React.createRef();
    this.props.model.setSearchbarRef(this.searchBarRef);
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidMount () {
    this.props.model.init();
    if (this.props.model.searchString !== '') {
      this.props.model.onSearchChangeCallback(this.props.model.searchString);
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  handleOnSearch = (searchString) => {
    this.props.model.handleOnSearch(searchString);
  }

  handleTableChange = (type, props) => {
    if (type === 'sort') {
      this.props.model.sortCreatives(props.sortField, props.sortOrder);
      return;
    }
    this.props.model.init(props.page);
  }

  handleNavEvent = (event) => {
    event && event.stopPropagation();
  }

  renderCreateBtnAndFilter = () => {
    return (
      <div className={styles.operationArea}>
        <PermissionChecker permissionAware={hasFuncs(Permission.CREATIVE_WRITE)}>
          <Link
            to={'/creatives/new'}
            className={`btn btn-tertiary btn-sm`}
          >
            {i18n.t<string>('creativeList.labels.newCreative')}
          </Link>
        </PermissionChecker>
        <SearchBar
          ref={this.searchBarRef}
          model={{
            placeholder: i18n.t<string>('creativeList.placeholders.searchbar'),
            defaultValue: this.props.model.searchString,
            search: this.handleOnSearch
          }}
        />
      </div>
    );
  }

  renderEditBar = () => {
    const model = this.props.model;
    const cloneCreatives = (event) => {
      event && event.stopPropagation();
      model.cloneCreatives();
    };

    const canDeleteCreatives = model.canDeleteSelectedCreatives(model.state.selectedCreatives);
    const batchDeleteClassName = this.cssClassNames('batchOperation', {
      'not-allow': !canDeleteCreatives
    });
    const isSelectedCreativesProcessing = model.isSelectedCreativesProcessing(model.state.selectedCreatives);
    const batchCopyClassName = this.cssClassNames('batchOperation', {
      'not-allow': isSelectedCreativesProcessing
    });
    const selectedCreativesCannotUnbind = model.getSelectedCreativesCannotUnbind();
    return (
      <ListEditBar selectedItems={model.state.selectedCreatives} handleRemoveSelect={this.props.model.clearSelectedCreatives}>
        <ListEditBar.Dropdown title={i18n.t<string>('creativeList.labels.bindingCampaign')} id='creativeAction' onClick={this.handleNavEvent}>
          <ListEditBar.Dropdown.Item onClick={model.updateBindState.bind(model, true)}>{i18n.t<string>('creativeList.labels.enableBindState')}</ListEditBar.Dropdown.Item>
          {
            selectedCreativesCannotUnbind.length > 0 ?
              <OverlayTrigger
                placement={'right'}
                trigger={['hover', 'focus']}
                overlay={
                  <Tooltip id={'bind-error'}>
                    {i18n.t<string>('creativeList.labels.cannotUnBindSomeCreative', { creatives: selectedCreativesCannotUnbind.join(', ') })}
                  </Tooltip>
                }
              >
                <div>
                  <ListEditBar.Dropdown.Item disabled={true}>
                    {i18n.t<string>('creativeList.labels.disableBindState')}
                  </ListEditBar.Dropdown.Item>
                </div>
              </OverlayTrigger> :
              <ListEditBar.Dropdown.Item onClick={model.updateBindState.bind(model, false)}>{i18n.t<string>('creativeList.labels.disableBindState')}</ListEditBar.Dropdown.Item>
          }
        </ListEditBar.Dropdown>
        <div className={batchCopyClassName}>
          {isSelectedCreativesProcessing &&
            <div className={styles.tooltip}>
              {i18n.t<string>('creativeList.labels.cannotCloneHint')}
            </div>
          }
          <ListEditBar.Item>
            <ListEditBar.Link disabled={isSelectedCreativesProcessing} onClick={cloneCreatives}>
              {i18n.t<string>('creativeList.labels.copyCreative')}
            </ListEditBar.Link>
          </ListEditBar.Item>
        </div>
        <div className={batchDeleteClassName}>
          {!canDeleteCreatives &&
            <div className={styles.tooltip}>
              {i18n.t<string>('creativeList.labels.cannotDeleteHint')}
            </div>
          }
          <ListEditBar.Item>
            <ListEditBar.Link disabled={!canDeleteCreatives} onClick={model.showDeleteCreativeModal.bind(model, model.state.selectedCreatives)}>
              {i18n.t<string>('campaignList.labels.deleteCampaign')}
            </ListEditBar.Link>
          </ListEditBar.Item>
        </div>
      </ListEditBar>
    );
  }

  renderCreativeList = (viewModelData) => {
    const model = this.props.model;
    const rowClasses = (creative, rowIndex) => {
      let classes: any = null;
      if (rowIndex === 0) {
        classes = styles.summaryRow;
      }
      return classes;
    };

    return (
      <StickableBootstrapTable
        stickFirstColumn
        stickLastColumn
        remote
        keyField='id'
        data={viewModelData}
        columns={this.props.model.getColumnsToRender()}
        rowClasses={rowClasses}
        summaryData={model.state.summaryData}
        onTableChange={this.handleTableChange}
        defaultSorted={model.sortDescriptor}
        noDataIndication={i18n.t<string>('creativeList.labels.noData')}
        pagination={{
          page: model.state.pagination ? model.state.pagination.page : 1,
          sizePerPage: model.sizePerPage,
          totalSize: model.state.pagination ? model.state.pagination.totalCount : 10
        }}
      />
    );
  }

  renderModal (modalData) {
    const model = this.props.model;
    const ModalContent: typeof React.Component = modalData.component;
    return (
      <Modal
        className={styles.creativeListModal}
        fullScreen={modalData.fullScreen}
        title={modalData.title}
        primaryButton={
          modalData.confirmBtnData ? {
            title: modalData.confirmBtnData.title,
            callback: modalData.confirmBtnData.callback
          } : undefined
        }
        secondaryButton={{
          title: i18n.t<string>('common.buttons.close'),
          callback: model.hideModal
        }}
        dismiss={model.hideModal}
        animation={false}
      >
        {ModalContent ? <ModalContent {...modalData.componentProps} /> : modalData.message}
      </Modal>
    );
  }

  renderTabs () {
    const model = this.props.model;
    const creativeTypes = Object.keys(CreativeType).filter(key => isNaN(Number(key)));
    creativeTypes.sort((a, b) => {
      if (creativeTypeOrder.indexOf(CreativeType[a]) === -1) {
        return 1;
      }
      if (creativeTypeOrder.indexOf(CreativeType[b]) === -1) {
        return -1;
      }
      return creativeTypeOrder.indexOf(CreativeType[a]) - creativeTypeOrder.indexOf(CreativeType[b]);
    });
    return compact(creativeTypes.map(creativeType =>
      model.isCreativeTypeSupport(CreativeType[creativeType]) ?
        <Nav.Item key={CreativeType[creativeType]}>
          <Nav.Link eventKey={creativeType}>
            {i18n.t<string>(`creativeType.${camelCase(creativeType)}`)}
          </Nav.Link>
        </Nav.Item>
      : undefined
    ));
  }

  render () {
    const model = this.props.model;
    const viewModelData = model.state.viewModelData;
    const hasSelectCreative = model.selectedCreatives.length > 0;
    return (
      <div className={styles.creativeList}>
        {model.state.loading && <LoadingIndicator/>}
        <div className={styles.titleBar}>
          {hasSelectCreative ? this.renderEditBar() : this.renderCreateBtnAndFilter()}
        </div>
        <div className={styles.contentArea}>
          <Nav
            activeKey={CreativeType[model.state.creativeTypeToShow]}
            onSelect={model.changeCreativeTypeToRender}
          >
            {this.renderTabs()}
          </Nav>
          <div className={styles.listContainer} key={i18n.language}>
            {this.renderCreativeList(viewModelData)}
          </div>
        </div>
        {model.state.modalData && this.renderModal(model.state.modalData)}
      </div>
    );
  }
}
