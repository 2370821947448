import React, { useCallback, useState } from 'react';
import styles from './bidPriceSetting.module.scss';
import i18n from 'i18n';
import _ from 'lodash';
import { getDecimalPlaceCount } from 'helper/CurrencyHelper';
import { FormikField } from 'components/common/form/field/FormikField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

interface BidPriceSettingProps {
  fieldName: string;
  type: string;
  currency: string;
  optimizes: string[];
  showDetail?: boolean;
}

export const BidPriceSetting: React.FC<BidPriceSettingProps> = ({
  type,
  currency,
  fieldName,
  optimizes,
  showDetail: showDetailProp
}) => {

  const hasShowDetailSetting = showDetailProp !== undefined;
  const [showDetail, setShowDetail] = useState(hasShowDetailSetting ? showDetailProp : true);

  const bidPriceInputStep = Math.pow(10, getDecimalPlaceCount(currency) * -1);

  const triggerShowDetail = useCallback(() => {
    setShowDetail(value => !value);
  }, []);

  const renderBidPriceSettings = (settingType: string) => {
    return optimizes.map(optimize => {
      return (
        <div key={`${fieldName}.${settingType}.${optimize}`} className={styles.optimizeContainer}>
          <span className={styles.optimize}>
            {i18n.t<string>(`bidPriceSetting.optimize.${optimize.toLowerCase()}`)}
          </span>
          <FormikField.InputGroup
            fieldContentWidth={'auto'}
            name={`${fieldName}.${settingType}.${optimize}`}
            prefix={currency}
            type='number'
            step={bidPriceInputStep}
          />
        </div>
      );
    });
  };

  return (
    <div className={styles.bidPriceSetting}>
      <div className={styles.titleArea}>
        <div className={styles.title}>
          {i18n.t<string>(`bidPriceSetting.labels.${_.camelCase(type)}`)}
        </div>
        {hasShowDetailSetting && (
          <div
            className={styles.triggerShowDetailBtn}
            onClick={triggerShowDetail}
          >
            <FontAwesomeIcon
              icon={showDetail ? faAngleUp : faAngleDown}
            />
            <div>
              {showDetail
              ? i18n.t<string>('creativeSetupFlow.labels.closeAdvanceSetting')
              : i18n.t<string>('creativeSetupFlow.labels.showAdvanceSetting')}
            </div>
          </div>
        )}
      </div>
      {showDetail && (
        <fieldset>
          <div className={styles.autoBidCapContainer}>
            <div className={styles.subTitle}>
              {i18n.t<string>('bidPriceSetting.labels.autoBidCapSetting')}
            </div>
            {renderBidPriceSettings('autoBidCap')}
          </div>
          <div className={styles.bidFloorContainer}>
            <div className={styles.subTitle}>
              {i18n.t<string>('bidPriceSetting.labels.bidFloorSetting')}
            </div>
            {renderBidPriceSettings('bidFloor')}
          </div>
        </fieldset>
      )}
    </div>
  );
};
