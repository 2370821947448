import React, { Fragment, useContext, useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import i18n from 'i18n';
import classNames from 'classnames/bind';
import styles from './draftSummaryStateContent.module.scss';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { useCallAPI } from 'hooks/useCallAPI';
import { ConfirmationColumns } from 'core/draft/Draft';
import { StateDataContext } from 'containers/Common/StateDataContext';
import { DefaultDraftSummaryStateContentModel } from './DraftSummaryStateContentModel';
import { DraftSourceDetail } from './DraftSourceDetail';

const cssClassNames = classNames.bind(styles);

export const DraftSummaryStateContent: React.FC<{
  model: DefaultDraftSummaryStateContentModel
}> = ({
  model
}) => {

  const { data, setData } = useContext(StateDataContext);
  const {
    loading,
    callAPIs
  } = useCallAPI();
  const [confirmations, setConfirmations] = useState<any[]>([]);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const fetchConfirmations = useCallback(async () => {
    const targets: any[] = _.get(data, 'targets', []);
    callAPIs(
      [model.getValidationData.bind(model, data)],
      (validationData: {
        [l1ObjectId: number]: {
          status: any
        }
      }) => {
        const targetDetails = _.map(targets, (target) => {
          const confirmation = _.omit(target, ['id', 'orderId', 'l1ObjectId', 'hasError']);
          return {
            ...confirmation,
            order: confirmation.order.label,
            l1Object: confirmation.l1Object.label,
            channel: _.get(data, 'l1Object.channel', ''),
            creative: confirmation.includeBinding ? 'Yes' : 'No',
            copies: confirmation.numberOfCopies,
            status: _.get(validationData, `[${confirmation.l1Object.value}].status`, [])
          };
        });
        setConfirmations(targetDetails);
      }
    );
  }, [model, data, callAPIs]);

  const handleCanGoNext = useCallback(() => {
    let hasError = false;
    _.forEach(confirmations, confirmation => {
      hasError = hasError || _.filter(_.get(confirmation, 'status', []), (info) => _.get(info, 'type', '') === 'error').length > 0;
    });
    if (model.canGoNext !== !hasError) {
      model.setCanGoNext(!hasError);
      setData({
        ...data
      });
    }
  }, [model, data, setData, confirmations]);

  useEffect(() => {
    if (_.isEmpty(confirmations)) {
      fetchConfirmations();
    }
  }, [confirmations, fetchConfirmations]);

  useEffect(() => {
    handleCanGoNext();
  }, [handleCanGoNext]);

  useEffect(() => {
    const handler = model.event.add(({ loading }) => {
      setSaveLoading(loading);
    });
    return () => {
      model.event.remove(handler);
    };
  }, [model.event]);

  const renderConfirmations = () => {

    return (
      <div className={styles.confirmations}>
        <div className={styles.row}>
          {_.map(Object.values(ConfirmationColumns), (value, index) => {
            const cellTitleClassName = cssClassNames('cell', 'title', {
              status: value === ConfirmationColumns.STATUS
            });
            return (
              <span className={cellTitleClassName} key={index}>
                {i18n.t<string>(`draftSummaryStateContent.confirmations.labels.${value}`)}
              </span>
            );
          })}
        </div>
        {confirmations.length > 0 &&
          _.map(confirmations, (confirmation, index) => (
            <div className={styles.row} key={index}>
              {_.map(Object.values(ConfirmationColumns), (value, _index) => {
                if (value !== ConfirmationColumns.STATUS) {
                  const content = _.get(confirmation, value, '');
                  const cellContentClassName = cssClassNames('cell', 'content');
                  return (
                    <span className={cellContentClassName} key={_index}>{content}</span>
                  );
                } else {
                  const statusList = _.get(confirmation, ConfirmationColumns.STATUS, []);
                  return (
                    <div key={_index}>
                      {_.map(statusList, (status, _ind) => {
                        const cellContentClassName = cssClassNames('cell', 'content', {
                          status: true,
                          passed: _.get(status, 'type', '') === 'passed',
                          danger:  _.get(status, 'type', '') === 'error'
                        });
                        return (
                          <span key={_ind} className={cellContentClassName}>{_.get(status, 'payload', '')}</span>
                        );
                      })}
                    </div>
                  );
                }
              })}
            </div>
          ))
        }
      </div>
    );
  };

  const renderSummary = () => {

    return (
      <div className={styles.draftSummaryStateContent}>
        <DraftSourceDetail />
        <div className={styles.header}>
          <div className={styles.title}>
            {i18n.t<string>('draftSummaryStateContent.labels.confirmationTitle')}
          </div>
        </div>
        <div className={styles.description}>
          {i18n.t<string>('draftSummaryStateContent.labels.description')}
        </div>
        {renderConfirmations()}
        <div className={styles.hints}>
          {_.map(i18n.t<string>('draftSummaryStateContent.labels.hints').split(/\r?\n/), (hint, index) => (
            <span key={index}>{hint}</span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {(loading || saveLoading) && <LoadingIndicator />}
      {renderSummary()}
    </Fragment>
  );
};
