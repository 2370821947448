import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { DEFAULT_INVENTORY, LIMITATION_TYPE, LimitationInventorySettings } from './limitationSettingsType';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { rtbInventories } from '../rtbLimitationInventory';
import { defaultItemSetting } from 'components/CampaignLimitation/SelectItemComponent';
import { SelectComponent } from 'components/CampaignLimitation/SelectComponent';
import { DefaultAsyncSourceSpanModel } from 'components/AsyncSourceSpan/AsyncSourceSpanModel';
import { SpaceCustomInputComponentModel } from 'components/CampaignLimitation';
import { renderProductGroupTips } from '../ProductGroupTips';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import i18n from 'i18n';

export const getOutdoorLimitationInventorySettings = (
  requiredOperateOfTaTypes: {[type: string]: string[]},
  limitationType: LIMITATION_TYPE,
  adType?: AdType,
  channel?: L1ObjectChannel,
  adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager()
): LimitationInventorySettings[] => [
  {
    name: DEFAULT_INVENTORY
  },
  {
    ...rtbInventories.geography,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.geography.name],
    addonFeature: `${limitationType}_country`,
    cb: adRequestSourceManager.getCountries.bind(adRequestSourceManager),
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchCountryCity',
    component: SelectComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    postCommentPropsGetter: (limitation, needShow) => {
      return needShow ? {
        model: new DefaultAsyncSourceSpanModel(() => i18n.t<string>('limitation.labels.geograthyCanNotNull'))
      } : undefined;
    }
  },
  {
    ...rtbInventories.adx,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.adx.name],
    addonFeature: `${limitationType}_adexchange`,
    cb: adRequestSourceManager.getAdx.bind(adRequestSourceManager),
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchAdx',
    component: SelectComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    postCommentPropsGetter: (limitation, needShow) => {
      return needShow ? {
        model: new DefaultAsyncSourceSpanModel(() => i18n.t<string>('limitation.labels.adxCanNotNull'))
      } : undefined;
    }
  },
  {
    ...rtbInventories.adspace,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.adspace.name],
    ignoreValidateOption: true,
    addonFeature: `${limitationType}_asiamax_space`,
    cb: () => adType ? adRequestSourceManager.getSSPSpacesByAdType(adType, channel, true) : adRequestSourceManager.getSSPSpaces(),
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      categorySelectable: false
    },
    searchPlaceholder: 'limitation.placeholders.searchSpace',
    needInitExtra: true,
    customInputModelGetter: () => new SpaceCustomInputComponentModel(),
    component: SelectComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    tipsConfig: {
      renderTips: (limitation: SelectOptions) => {
        return limitation.isGroup ? renderProductGroupTips(limitation) : undefined;
      }
    }
  }
];
