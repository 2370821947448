import { OutdoorType } from 'core/creative/Creative';
import { BasicOutdoorFormModel, OutdoorFormProps } from './OutdoorFormModel';
import { OutdoorForm } from './OutdoorForm';
import i18n from 'i18n';

export class SandboxOutdoorFormModel extends BasicOutdoorFormModel<SandboxOutdoorFormModel> {

  override get adFormat () {
    return 'SANDBOX_OUTDOOR';
  }

  override get useMediaDuration () {
    return true;
  }

  override get needSelfCheckDocument () {
    return false;
  }

  override getOutdoorTypeOptions () {
    return [
      {
        label: i18n.t<string>('outdoorForm.labels.outdoorType1'),
        value: OutdoorType.VIDEO
      }
    ];
  }

  override getFormContent (): (props: OutdoorFormProps<SandboxOutdoorFormModel>) => JSX.Element {
    return OutdoorForm<SandboxOutdoorFormModel>;
  }

  override getDefaultDuration = (_1: OutdoorType, oldDuration: number) => {
    const notInDurationOption = oldDuration !== 0 && oldDuration !== 30;
    if (notInDurationOption) {
      return 30;
    }

    return oldDuration;
  }

  override getVideoHints () {
    return [
      i18n.t<string>('creativeSetupFlow.labels.videoTypeHint')
    ];
  }

  override validateVideo (
    _1: number,
    _2: number,
    fileData: MediaFieldData
  ) {
    const validTypes = ['video/mp4'];
    if (this.isFileFieldEmpty(fileData)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!this.hasFile(fileData)) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      return i18n.t<string>('creativeSetupFlow.labels.videoTypeHint');
    }
  }
}
