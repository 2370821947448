import { ModalState } from 'containers/Common/ModalStateFactory';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import i18n from 'i18n';
import { DraftSetupStateContent } from './DraftSetupStateContent';
import { DefaultDraftSetupStateContentModel, EdiMaxCampaignDraftSetupStateContentModel, FbAdSetDraftSetupStateContentModel, HamiVideoCampaignDraftSetupStateContentModel, PICCampaignDraftSetupStateContentModel, RetailCampaignDraftSetupStateContentModel, RtbCampaignDraftSetupStateContentModel } from './DraftSetupStateContentModel';

export abstract class DraftSetupState implements ModalState {

  back?;
  next?;
  close?;

  constructor (
    protected orderOptions: SelectOptions[],
    protected l1Object: L1Object,
    protected l2ObjectsToCreateDraft: {
      id: number | string,
      isDraft: boolean
    } []
  ) {}

  abstract get title ();

  titleFormatter = (title) => {
    if (i18n.language === 'en' && this.l2ObjectsToCreateDraft.length > 1) {
      return title = title + 's';
    }
    return title;
  }

  abstract get contentModel (): DefaultDraftSetupStateContentModel;

  get content () {
    return DraftSetupStateContent;
  }

  get btnData (): any {
    return {
      primaryButton: this.next && {
        title: i18n.t<string>('draftCreateModal.buttons.goSummary'),
        callback: this.next
      },
      secondaryButton: this.close && {
        title: i18n.t<string>('common.buttons.cancel'),
        callback: this.close
      }
    };
  }

  canGoNext = () => {
    return this.contentModel.canGoNext;
  }
}

export class RtbCampaignDraftSetupState extends DraftSetupState {

  _contentModel = new RtbCampaignDraftSetupStateContentModel(this.orderOptions);

  get title () {
    return this.titleFormatter(i18n.t<string>('draftCreateModal.title', {
      l2Object: i18n.t<string>(`l2ObjectName.${L1ObjectChannel.RTB.toLowerCase()}`),
      number: this.l2ObjectsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contentModel;
  }
}

export class FbAdSetDraftSetupState extends DraftSetupState {

  _contentModel = new FbAdSetDraftSetupStateContentModel(this.orderOptions);

  get title () {
    return this.titleFormatter(i18n.t<string>('draftCreateModal.title', {
      l2Object: i18n.t<string>(`l2ObjectName.${L1ObjectChannel.FB.toLowerCase()}`),
      number: this.l2ObjectsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contentModel;
  }
}

export class RetailCampaignDraftSetupState extends DraftSetupState {

  _contentModel = new RetailCampaignDraftSetupStateContentModel(this.orderOptions);

  get title () {
    return this.titleFormatter(i18n.t<string>('draftCreateModal.title', {
      l2Object: i18n.t<string>(`l2ObjectName.${L1ObjectChannel.RETAIL_MEDIA.toLowerCase()}`),
      number: this.l2ObjectsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contentModel;
  }
}

export class EdiMaxCampaignDraftSetupState extends DraftSetupState {

  _contentModel = new EdiMaxCampaignDraftSetupStateContentModel(this.orderOptions);

  get title () {
    return this.titleFormatter(i18n.t<string>('draftCreateModal.title', {
      l2Object: i18n.t<string>(`l2ObjectName.${L1ObjectChannel.EDIMAX.toLowerCase()}`),
      number: this.l2ObjectsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contentModel;
  }
}

export class PICCampaignDraftSetupState extends DraftSetupState {

  _contentModel = new PICCampaignDraftSetupStateContentModel(this.orderOptions);

  get title () {
    return this.titleFormatter(i18n.t<string>('draftCreateModal.title', {
      l2Object: i18n.t<string>(`l2ObjectName.${L1ObjectChannel.PIC.toLowerCase()}`),
      number: this.l2ObjectsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contentModel;
  }
}

export class HamiVideoCampaignDraftSetupState extends DraftSetupState {

  _contentModel = new HamiVideoCampaignDraftSetupStateContentModel(this.orderOptions);

  get title () {
    return this.titleFormatter(i18n.t<string>('draftCreateModal.title', {
      l2Object: i18n.t<string>(`l2ObjectName.${L1ObjectChannel.HAMI_VIDEO.toLowerCase()}`),
      number: this.l2ObjectsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contentModel;
  }
}
