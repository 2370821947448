import { L1ObjectObjective } from 'core/l1Object/L1Object';
import { DefaultRtbCampaignBasicFormModel } from './RtbCampaignBasicFormModel';
import { conversionTrackingTypeOptions, RtbCampaignPlanType, VideoAdViewObjective } from 'core/rtbCampaign/RtbCampaign';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import i18n from 'i18n';
import { compact, isUndefined, omitBy } from 'lodash';

export abstract class SupportVideoViewsFormModel extends DefaultRtbCampaignBasicFormModel {

  abstract supportProgressAdViewObjective: boolean;

  override get conversionTrackingTypeOptions () {
    return conversionTrackingTypeOptions;
  }

  getVideoAdViewObjectiveOptions = (priceModel: RtbCampaignPlanType) => {
    if (priceModel !== RtbCampaignPlanType.FCPV) {
      return undefined;
    }

    return compact([
      {
        value: VideoAdViewObjective.DEFAULT,
        label: i18n.t<string>('campaign.labels.videoObjectiveDefault'),
        extra: i18n.t<string>('campaign.descriptions.videoObjectiveDefault')
      },
      this.supportProgressAdViewObjective
        ? {
          value: VideoAdViewObjective.PROGRESS,
          label: i18n.t<string>('campaign.labels.videoObjectiveProgress')
        }
        : undefined,
      {
        value: VideoAdViewObjective.METRIC,
        label: i18n.t<string>('campaign.labels.videoObjectiveMetric')
      }
    ]);
  }

  getRtbOptionsMap () {
    const optionsMap = super.getRtbOptionsMap();
    const enableOptimizedPrice = this.addonFeatureManager.isFeatureEnable(
      ADDONFEATURE.CAMPAIGN.ENABLE_OPTIMIZED_PRICE
    );
    return {
      ...optionsMap,
      [L1ObjectObjective.AWARENESS]: omitBy({
        [RtbCampaignPlanType.RS]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS,
          L2ObjectOptimizationGoal.VIDEO_VIEWS_REACH
        ],
        [RtbCampaignPlanType.FCPM]: enableOptimizedPrice
          ? [
            L2ObjectOptimizationGoal.REACH,
            L2ObjectOptimizationGoal.VIDEO_VIEWS,
            L2ObjectOptimizationGoal.IMPRESSIONS,
            L2ObjectOptimizationGoal.VIDEO_VIEWS_REACH
          ]
          : [
            L2ObjectOptimizationGoal.REACH,
            L2ObjectOptimizationGoal.IMPRESSIONS
          ],
        [RtbCampaignPlanType.FCPV]: enableOptimizedPrice
          ? [
            L2ObjectOptimizationGoal.VIDEO_VIEWS,
            L2ObjectOptimizationGoal.IMPRESSIONS,
            L2ObjectOptimizationGoal.VIDEO_VIEWS_REACH
          ]
          : [
            L2ObjectOptimizationGoal.VIDEO_VIEWS,
            L2ObjectOptimizationGoal.VIDEO_VIEWS_REACH
          ],
        [RtbCampaignPlanType.FVCPM]: enableOptimizedPrice ? [
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.VIDEO_VIEWS_REACH
        ] : undefined,
        [RtbCampaignPlanType.DCPM]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ]
      }, isUndefined),
      [L1ObjectObjective.UNSPECIFIED]: {
        [RtbCampaignPlanType.RS]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPV]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPM]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FVCPM]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.DCPM]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        rb: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ]
      }
    };
  }
}
