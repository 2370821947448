import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { CreateThirdPartyRectangleFormModel, EditThirdPartyRectangleFormModel } from './ThirdPartyRectangleFormModel';

export class EditHamiVideoThirdPartyRectangleFormModel extends EditThirdPartyRectangleFormModel {

  override get campaignAdType (): AdType {
    return AdType.HAMI_VIDEO_THIRD_PARTY_RECTANGLE;
  }
}

export class CreateHamiVideoThirdPartyRectangleFormModel extends CreateThirdPartyRectangleFormModel {
  override get campaignAdType (): AdType {
    return AdType.HAMI_VIDEO_THIRD_PARTY_RECTANGLE;
  }
}
