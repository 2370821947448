import { defaultItemSetting, ItemType } from 'components/CampaignLimitation/SelectItemComponent';
import { DEFAULT_INVENTORY, LIMITATION_TYPE, LimitationInventorySettings } from './limitationSettingsType';
import { defaultTo, get, map, partial } from 'lodash';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { DefaultLimitationManager } from 'core/limitation/LimitationManager';
import { renderKeywordSettingSearchResult } from '../LimitationSettingHintRenderFunctions';
import { DefaultAsyncSourceSpanModel } from 'components/AsyncSourceSpan/AsyncSourceSpanModel';
import { KeywordComponent } from 'components/CampaignLimitation/KeywordComponent/KeywordComponent';
import { LocaleMeta } from 'core';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { OPERATE, OPERATES } from 'enum/Operate';
import { rtbInventories } from '../rtbLimitationInventory';
import { SelectComponent } from 'components/CampaignLimitation/SelectComponent';
import { DomainCustomInputComponentModel, SpaceCustomInputComponentModel } from 'components/CampaignLimitation';
import { renderProductGroupTips } from '../ProductGroupTips';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import i18n from 'i18n';

export const unicornkeywordName = [
  'unicornkeyword1',
  'unicornkeyword2',
  'unicornlanguage'
];

const limitationManager = new DefaultLimitationManager();

const keywordLimitationConfig: LimitationInventorySettings[] = [
  {
    name: 'unicornkeyword1',
    title: 'limitation.labels.unicornkeyword1',
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    postCommentPropsGetter: (limitation) => {
      const keyword1 = map(get(limitation['unicornkeyword1'], 'value', []), 'value');
      const keyword2 = map(get(limitation['unicornkeyword2'], 'value', []), 'value');
      const keywordlanguage = map(get(limitation['unicornlanguage'], 'value', [{ label: 'zh', value: 'zh' }]), 'value')[0];
      const keywordUseAndFeature = limitation['unicornkeyword1'] && limitation['unicornkeyword2'];
      const dataSource = partial(limitationManager.keywordEstimate.bind(limitationManager), {
        firstQueryParameters: keyword1,
        secondQueryParameters: keyword2,
        language: keywordlanguage
      });
      const dataDecorator = (data) => renderKeywordSettingSearchResult(i18n.t<string>('limitation.labels.unicornkeyword1'), data);
      return keywordUseAndFeature ? undefined : {
        key: `${keyword1.join(',')},${keyword2.join(',')},${keywordlanguage}`,
        model: new DefaultAsyncSourceSpanModel(dataSource, dataDecorator)
      };
    },
    component: KeywordComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    addonFeature: `campaign_keyword`,
    hideInMenu: true
    // validator: keywordValidator
  },
  {
    name: 'unicornkeyword2',
    title: 'limitation.labels.unicornkeyword2',
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    preCommentPropsGetter: (limitation) => {
      const keywordUseAndFeature = limitation['unicornkeyword1'] && limitation['unicornkeyword2'];
      const dataSource = () => keywordUseAndFeature ? i18n.t<string>('limitation.labels.and') : '';
      return keywordUseAndFeature ? {
        key: dataSource(),
        model: new DefaultAsyncSourceSpanModel(dataSource)
      } : undefined;
    },
    postCommentPropsGetter: (limitation) => {
      const keyword1 = map(get(limitation['unicornkeyword1'], 'value', []), 'value');
      const keyword2 = map(get(limitation['unicornkeyword2'], 'value', []), 'value');
      const keywordlanguage = map(get(limitation['unicornlanguage'], 'value', [{ label: 'zh', value: 'zh' }]), 'value')[0];
      const keywordUseAndFeature = limitation['unicornkeyword1'] && limitation['unicornkeyword2'];
      const dataSource = partial(limitationManager.keywordEstimate.bind(limitationManager), {
        firstQueryParameters: keyword1,
        secondQueryParameters: keyword2,
        language: keywordlanguage
      });
      const name = keywordUseAndFeature ?
        `${i18n.t<string>('limitation.labels.unicornkeyword1')} ${i18n.t<string>('limitation.labels.unicornkeyword2')}` :
        i18n.t<string>('limitation.labels.unicornkeyword2');
      const dataDecorator = (data) => renderKeywordSettingSearchResult(name, data);
      return {
        key: `${keyword1.join(',')},${keyword2.join(',')},${keywordlanguage}`,
        model: new DefaultAsyncSourceSpanModel(dataSource, dataDecorator)
      };
    },
    component: KeywordComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    addonFeature: `campaign_keyword`,
    hideInMenu: true
    // validator: keywordValidator
  }
];

const getArticleCategoriesInfoFunc = (categoryLimitations, adRequestSourceManager) => {
  if (categoryLimitations.length === 1) {
    return async () => {
      const categories = await adRequestSourceManager.getArticleCategories();
      const mainCategory = categoryLimitations[0].split('-')[0];
      const targetMainCategory = categories.find(category => category.label === mainCategory);
      if (targetMainCategory) {
        if (targetMainCategory.value === categoryLimitations[0]) {
          return targetMainCategory.extra;
        } else {
          const options = defaultTo(targetMainCategory.options, []);
          const option = options.find(option => option.value === categoryLimitations[0]);
          return get(option, 'extra', 0);
        }
      }
      return 0;
    };
  }
  return partial(limitationManager.categoriesUnionCount.bind(limitationManager), categoryLimitations);
};

export const getDefaultLimitationInventorySettings = (
  requiredOperateOfTaTypes: {[type: string]: string[]},
  advertiserId: number,
  limitationType: LIMITATION_TYPE,
  goSegments?: SelectOptions[],
  localeMeta?: LocaleMeta,
  adType?: AdType,
  channel?: L1ObjectChannel,
  supportKeywords: boolean = true,
  adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager()
): LimitationInventorySettings[] => {

  const supportOperates = [OPERATE.INCLUDE, OPERATE.EXCLUDE, OPERATE.PREFERRED, OPERATE.NONPREFERRED] as OPERATES[];
  const basicLimitationConfig: LimitationInventorySettings[] = [
    {
      name: DEFAULT_INVENTORY
    },
    {
      ...rtbInventories.geography,
      requiredOperate: requiredOperateOfTaTypes[rtbInventories.geography.name],
      addonFeature: `${limitationType}_country`,
      cb: () => adRequestSourceManager.getCountries(),
      singleSelect: false,
      itemSetting: defaultItemSetting(),
      searchPlaceholder: 'limitation.placeholders.searchCountryCity',
      component: SelectComponent,
      supportOperates,
      postCommentPropsGetter: (limitation, needShow) => {
        return needShow ? {
          model: new DefaultAsyncSourceSpanModel(() => i18n.t<string>('limitation.labels.geograthyCanNotNull'))
        } : undefined;
      }
    },
    {
      ...rtbInventories.carrier,
      requiredOperate: requiredOperateOfTaTypes[rtbInventories.carrier.name],
      addonFeature: `${limitationType}_carrier`,
      singleSelect: false,
      itemSetting: {
        ...defaultItemSetting(),
        i18nPrefix: rtbInventories.carrier.i18nPrefix
      },
      searchPlaceholder: 'limitation.placeholders.searchCarrier',
      cb: adRequestSourceManager.getCarriers.bind(adRequestSourceManager),
      supportOperates,
      component: SelectComponent
    },
    {
      ...rtbInventories.os,
      requiredOperate: requiredOperateOfTaTypes[rtbInventories.os.name],
      addonFeature: `${limitationType}_device`,
      cb: adRequestSourceManager.getOS.bind(adRequestSourceManager),
      supportOperates,
      singleSelect: false,
      itemSetting: defaultItemSetting(),
      searchPlaceholder: 'limitation.placeholders.searchOs',
      component: SelectComponent
    },
    {
      ...rtbInventories.device,
      requiredOperate: requiredOperateOfTaTypes[rtbInventories.device.name],
      addonFeature: `${limitationType}_device`,
      singleSelect: false,
      itemSetting: {
        ...defaultItemSetting(),
        i18nPrefix: rtbInventories.device.i18nPrefix
      },
      searchPlaceholder: 'limitation.placeholders.searchDevice',
      cb: () => adRequestSourceManager.getDevice(),
      supportOperates,
      component: SelectComponent
    },
    {
      ...rtbInventories.agencySegment,
      requiredOperate: requiredOperateOfTaTypes[rtbInventories.agencySegment.name],
      ignoreValidateOption: true,
      addonFeature: 'agencySegment',
      title: i18n.t('limitation.labels.agencySegment', { name: get(localeMeta, 'property.addonProps.agencySegmentLimitationName', '') }),
      singleSelect: false,
      itemSetting: {
        ...defaultItemSetting(),
        categorySelectable: false
      },
      searchPlaceholder: 'limitation.placeholders.searchAgencySegment',
      cb: () => adRequestSourceManager.getAgencySegments(get(localeMeta, 'agencyId')),
      supportOperates,
      component: SelectComponent
    },
    {
      ...rtbInventories.segment,
      requiredOperate: requiredOperateOfTaTypes[rtbInventories.segment.name],
      ignoreValidateOption: true,
      addonFeature: `${limitationType}_target_audience`,
      title: goSegments ? 'limitation.labels.gojekSegment' : 'limitation.labels.segment',
      singleSelect: false,
      itemSetting: {
        ...defaultItemSetting(),
        type: ItemType.autoByGroupSetting,
        categorySelectable: false
      },
      searchPlaceholder: goSegments ? 'limitation.placeholders.searchGojekSegment' : 'limitation.placeholders.searchSegment',
      cb: goSegments ?
        () => goSegments :
        adRequestSourceManager.getSegments.bind(adRequestSourceManager, advertiserId),
      supportOperates,
      component: SelectComponent
    },
    {
      ...rtbInventories.tenmaxAudience,
      requiredOperate: requiredOperateOfTaTypes[rtbInventories.tenmaxAudience.name],
      addonFeature: `${limitationType}_tenmax_audience`,
      singleSelect: false,
      itemSetting: {
        ...defaultItemSetting(),
        type: ItemType.withCategory,
        categorySelectable: false
      },
      searchPlaceholder: 'limitation.placeholders.searchTenmaxSegments',
      cb: adRequestSourceManager.getTenmaxSegments.bind(adRequestSourceManager),
      supportOperates,
      component: SelectComponent
    },
    {
      ...rtbInventories.contentcat,
      requiredOperate: requiredOperateOfTaTypes[rtbInventories.contentcat.name],
      addonFeature: `${limitationType}_content_category`,
      singleSelect: false,
      itemSetting: {
        ...defaultItemSetting(),
        type: ItemType.withCategory,
        i18nPrefix: rtbInventories.contentcat.i18nPrefix
      },
      searchPlaceholder: 'limitation.placeholders.searchContentCat',
      cb: adRequestSourceManager.getArticleCategories.bind(adRequestSourceManager),
      supportOperates,
      postCommentPropsGetter: (limitation) => {
        const value = map(get(limitation['contentcat'], 'value', []), 'value');
        const dataSource = getArticleCategoriesInfoFunc(value, adRequestSourceManager);
        const dataDecorator = (data) => i18n.t<string>('limitation.contentCategory.countHint', { count: data });
        return {
          key: value.join(','),
          model: new DefaultAsyncSourceSpanModel(dataSource, dataDecorator)
        };
      },
      component: SelectComponent
    },
    {
      ...rtbInventories.unicornkeywords,
      addonFeature: `${limitationType}_keyword`,
      singleSelect: false,
      itemSetting: defaultItemSetting(),
      searchPlaceholder: '',
      supportOperates,
      component: KeywordComponent
    },
    {
      ...rtbInventories.adx,
      requiredOperate: requiredOperateOfTaTypes[rtbInventories.adx.name],
      addonFeature: `${limitationType}_adexchange`,
      cb: adRequestSourceManager.getAdx.bind(adRequestSourceManager),
      supportOperates,
      singleSelect: false,
      itemSetting: defaultItemSetting(),
      searchPlaceholder: 'limitation.placeholders.searchAdx',
      component: SelectComponent,
      postCommentPropsGetter: (limitation, needShow) => {
        return needShow ? {
          model: new DefaultAsyncSourceSpanModel(() => i18n.t<string>('limitation.labels.adxCanNotNull'))
        } : undefined;
      }
    },
    {
      ...rtbInventories.spaceType,
      requiredOperate: requiredOperateOfTaTypes[rtbInventories.spaceType.name],
      addonFeature: `${limitationType}_spaceType`,
      cb: adRequestSourceManager.getSpaceTypes.bind(adRequestSourceManager),
      supportOperates,
      singleSelect: false,
      itemSetting: {
        ...defaultItemSetting(),
        i18nPrefix: rtbInventories.spaceType.i18nPrefix
      },
      searchPlaceholder: 'limitation.placeholders.searchSpaceType',
      component: SelectComponent
    },
    {
      ...rtbInventories.adspace,
      requiredOperate: requiredOperateOfTaTypes[rtbInventories.adspace.name],
      ignoreValidateOption: true,
      addonFeature: `${limitationType}_asiamax_space`,
      cb: () => adType && adType !== AdType.UNKNOW ? adRequestSourceManager.getSSPSpacesByAdType(adType, channel) : adRequestSourceManager.getSSPSpaces(),
      supportOperates,
      singleSelect: false,
      itemSetting: {
        ...defaultItemSetting(),
        categorySelectable: false
      },
      searchPlaceholder: 'limitation.placeholders.searchSpace',
      needInitExtra: true,
      customInputModelGetter: () => new SpaceCustomInputComponentModel(),
      component: SelectComponent,
      tipsConfig: {
        renderTips: (limitation: SelectOptions) => {
          return limitation.isGroup ? renderProductGroupTips(limitation) : undefined;
        }
      }
    },
    {
      ...rtbInventories.contentlang,
      requiredOperate: requiredOperateOfTaTypes[rtbInventories.contentlang.name],
      addonFeature: `${limitationType}_content_language`,
      singleSelect: false,
      itemSetting: {
        ...defaultItemSetting(),
        i18nPrefix: rtbInventories.contentlang.i18nPrefix
      },
      searchPlaceholder: 'limitation.placeholders.searchContentLang',
      cb: adRequestSourceManager.getContentLanguages.bind(adRequestSourceManager),
      supportOperates,
      component: SelectComponent
    },
    {
      ...rtbInventories.adcat,
      requiredOperate: requiredOperateOfTaTypes[rtbInventories.adcat.name],
      addonFeature: `${limitationType}_space_category`,
      singleSelect: false,
      itemSetting: {
        ...defaultItemSetting(),
        i18nPrefix: rtbInventories.adcat.i18nPrefix
      },
      searchPlaceholder: 'limitation.placeholders.searchAdCat',
      cb: adRequestSourceManager.getTenmaxCategories.bind(adRequestSourceManager),
      supportOperates,
      component: SelectComponent
    },
    {
      ...rtbInventories.domain,
      requiredOperate: requiredOperateOfTaTypes[rtbInventories.domain.name],
      ignoreValidateOption: true,
      addonFeature: `${limitationType}_domain`,
      singleSelect: false,
      itemSetting: defaultItemSetting(),
      searchPlaceholder: 'limitation.placeholders.searchDomains',
      cb: adRequestSourceManager.getDomains.bind(adRequestSourceManager, channel),
      supportOperates,
      needInitExtra: true,
      customInputModelGetter: () => new DomainCustomInputComponentModel(),
      component: SelectComponent,
      tipsConfig: {
        renderTips: (limitation: SelectOptions) => {
          return limitation.isGroup ? renderProductGroupTips(limitation) : undefined;
        }
      }
    },
    {
      ...rtbInventories.adFormat,
      requiredOperate: requiredOperateOfTaTypes[rtbInventories.adFormat.name],
      ignoreAddonFeature: true,
      cb: () => ([{
        label: 'instl',
        value: 'instl'
      }]),
      supportOperates,
      singleSelect: false,
      itemSetting: {
        ...defaultItemSetting(),
        i18nPrefix: rtbInventories.adFormat.i18nPrefix
      },
      searchPlaceholder: 'limitation.placeholders.searchAdFormat',
      component: SelectComponent
    }
  ];
  if (supportKeywords) {
    return [...basicLimitationConfig, ...keywordLimitationConfig];
  }
  return basicLimitationConfig;
};

export const inventoryNameList = (limitationType: LIMITATION_TYPE) => {
  return getDefaultLimitationInventorySettings({}, 0, limitationType).map(inventory => inventory.name);
};
