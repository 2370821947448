
import { CreativeType } from 'core/creative/Creative';
import { Dayparts } from 'core/dayparts/Dayparts';
import { BidStrategy, L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { LimitationData } from 'core/limitation/Limitation';
import { Pmp } from 'core/pmp/Pmp';
import * as SelectOptionsUtils from 'utils/SelectOptionsUtils';

export type RtbCampaign = {
  basic: RtbCampaignBasic;
  limitations: LimitationData;
};

export type RtbCampaignBasic = {
  id?: number;
  name: string;
  advertiserId: number;
  orderId: number;
  budget: number;
  startDate: string;
  endDate: string;
  createDate?: string;
  priceModel: RtbCampaignPlanType;
  orderPrice?: number;
  optimize: L2ObjectOptimizationGoal;
  bidPrice?: number;
  creativeDeliverType: CreativeDeliverType;
  deliverType: DeliverType;
  state?: CampaignState;
  spents?: number;
  currencyRate?: number;
  olapActualSpent?: number;
  olapExpectSpent?: number;
  expectedSpent: number;
  impres?: number;
  clicks?: number;
  dailyTargetBudget: number | null;
  frequency?: CampaignFrequency;
  dayPart?: Dayparts;
  checkpoints: CheckPoint[];
  dealIds?: string[];
  enableMonitor?: boolean;
  adType: AdType;
  videoAdViewObjective?: VideoAdViewObjectiveType;
  conversionTracking?: ConversionTracking;
  viewTrackingCodes?: ViewTrackingCode[];
  videoProgressTrackingCode?: VideoProgressTrackingCode;
  tags: Array<string>;
  convTrackEvent?: string;
  orderPriceEnable: boolean;
  retailType?: string;
  isDraft?: boolean;
  draftId?: number;
  outdoorDeliverType?: OutdoorDeliverType;
  goGanGroupId?: number;
  ageMin: number;
  ageMax: number;
};

export type RtbCampaignFormData = {
  basic: RtbCampaignBasicFormData;
  limitations: LimitationData;
};

export type RtbCampaignBasicFormData = RtbCampaignBasic & {
  messages: string;
  bidStrategy: BidStrategy;
  bindings: {
    bindingId: string,
    active: boolean
  }[];
};

export type RtbCampaignListBasic = Omit<RtbCampaignBasic, 'orderPriceEnable'> & {
  effectiveStatus: string;
  viewable: number;
  convs: number;
  adView: number;
  magnificationRatio: number;
  l2ObjectId: number;
  additionalInfo: AdditionalInfo;
  uuCount: number;
  report?: {
    results: number;
  };
  bindings?: {
    bindingId: string;
    active: boolean;
  };
};

export type AdditionalInfo = {
  creativeAmount: CreativeAmount,
  limitations: { [key: string]: object[] },
  viewTrackingSize?: number,
  conversionTracking?: AdditionalInfoConversionTracking,
  videoProgressTrackingOffset?: number,
  pmp?: Pmp;
};

export type CreativeAmount = {
  bindingCount: number,
  enableCount: number
};

export type AdditionalInfoConversionTracking = {
  conversionType: string;
  id: number;
  name: string;
  conversionId: string;
};

export type ConversionTracking = {
  label: string;
  value: string;
};

export enum ViewTrackingType {
  PIXEL = 'pixel',
  JS = 'js'
}

export enum ViewTrackingTrigger {
  VIEWABLE_IMPRESSION = 'viewableImpression',
  IMPRESSION = 'impression'
}

export type ViewTrackingCode = {
  originTrackingCode: string;
  trackingCode: string;
  trackingType: ViewTrackingType;
  trigger: ViewTrackingTrigger;
};

export type VideoProgressTrackingCode = {
  offset: number;
  code: string;
};

export type CampaignFrequency = {
  maxFrequency: number;
  intervalDays: number;
};

export type CheckPoint = {
  target: number;
  time: string;
  utime: string;
};

export type VideoAdViewObjectiveType = {
  videoAdEvent: VideoAdViewObjective.DEFAULT;
} | {
  videoAdEvent: VideoAdViewObjective.PROGRESS;
  offset: number;
} | {
  videoAdEvent: VideoAdViewObjective.METRIC;
  videoAdMetricEvent: VideoAdMetricEvent;
};

export enum AdType {
  DISPLAY = 'DISPLAY',
  VIDEO = 'VIDEO',
  COMBO = 'COMBO',
  EDIMAX = 'EDIMAX',
  PIC_SHORT = 'PIC_SHORT',
  PIC_LONG = 'PIC_LONG',
  THIRD_PARTY = 'THIRD_PARTY',
  THIRD_PARTY_BOTTOM = 'THIRD_PARTY_BOTTOM',
  THIRD_PARTY_RECTANGLE = 'THIRD_PARTY_RECTANGLE',
  UNKNOW = 'UNKNOW',
  ONE_FOR_ALL_DISPLAY = 'ONE_FOR_ALL_DISPLAY',
  RETAIL = 'RETAIL',
  PILOT_TV = 'PILOT_TV',
  CTV = 'CTV',
  MESSAGE = 'MESSAGE',
  PMP_PIC_SHORT = 'PMP_PIC_SHORT',
  PMP_PIC_LONG = 'PMP_PIC_LONG',
  HAMI_VIDEO_DISPLAY = 'HAMI_VIDEO_DISPLAY',
  HAMI_VIDEO_VIDEO = 'HAMI_VIDEO_VIDEO',
  HAMI_VIDEO_COMBO = 'HAMI_VIDEO_COMBO',
  HAMI_VIDEO_CONNECTED_TV_IMAGE = 'HAMI_VIDEO_CONNECTED_TV_IMAGE',
  HAMI_VIDEO_THIRD_PARTY_RECTANGLE = 'HAMI_VIDEO_THIRD_PARTY_RECTANGLE',
  SANDBOX_OUTDOOR = 'SANDBOX_OUTDOOR'
}

export enum RtbLegacyOptimize {
  CPC = 'CPC',
  CPV = 'CPV',
  CPM = 'CPM',
  VCPM = 'vCPM'
}

export enum RtbOptimize {
  REACH = 'REACH',
  VIDEO_VIEWS = 'VIDEO_VIEWS',
  VIDEO_VIEWS_REACH = 'VIDEO_VIEWS_REACH',
  IMPRESSIONS = 'IMPRESSIONS',
  CLICKS = 'CLICKS',
  SALES = 'SALES',
  LINK_CLICKS = 'LINK_CLICKS',
  OFFSITE_CONVERSIONS = 'OFFSITE_CONVERSIONS'
}

export enum RtbCampaignPlanType {
  RS = 'rs',
  FCPC = 'fcpc',
  FCPV = 'fcpv',
  FCPM = 'fcpm',
  FVCPM = 'fvcpm',
  DCPM = 'dcpm'
  // RB = 'rb'
}

export enum CreativeDeliverType {
  OPTIMIZE = 1,
  AVERAGE = 2
}

export enum OutdoorDeliverType {
  OPTIMIZE = 1,
  AVERAGE = 2
}

export enum CampaignState {
  ACTIVATE = 1,
  DEACTIVATE = 2,
  DELETE = 3,
  STOPPING = 4
}

export enum DailyBudgetPlan {
  SCHEDULE,
  DAILY
}

export enum VideoAdViewObjective {
  DEFAULT = 'defualt',
  PROGRESS = 'progress',
  METRIC = 'metric'
}

export enum VideoAdMetricEvent {
  FIRSTQUARTILE = 'firstQuartile',
  MIDPOINT = 'midpoint',
  // THIRDQUARTILE = 'thirdQuartile',
  COMPLETE = 'complete'
}

export enum DeliverType {
  STANDARD = 1,
  ACCELERATED = 2
}

export enum TrackEvent {
  CLICK = 'click',
  VIEW = 'view'
}

export const AD_TYPE_MAP_CREATIVE_TYPE = {
  [AdType.ONE_FOR_ALL_DISPLAY]: [CreativeType.ONE_FOR_ALL_DISPLAY, CreativeType.IMAGE],
  [AdType.DISPLAY]: [CreativeType.IMAGE, CreativeType.NATIVE, CreativeType.HTML5],
  [AdType.VIDEO]: [CreativeType.VIDEO],
  [AdType.THIRD_PARTY]: [CreativeType.THIRD_PARTY, CreativeType.HTML5],
  [AdType.THIRD_PARTY_BOTTOM]: [CreativeType.THIRD_PARTY, CreativeType.HTML5, CreativeType.CUSTOM_BOTTOM],
  [AdType.THIRD_PARTY_RECTANGLE]: [CreativeType.THIRD_PARTY, CreativeType.HTML5, CreativeType.CUSTOM_RECTANGLE],
  [AdType.EDIMAX]: [CreativeType.EDIMAX],
  [AdType.PIC_SHORT]: [CreativeType.PIC_SHORT],
  [AdType.PIC_LONG]: [CreativeType.PIC_LONG],
  [AdType.PMP_PIC_SHORT]: [CreativeType.PIC_SHORT],
  [AdType.PMP_PIC_LONG]: [CreativeType.PIC_LONG],
  [AdType.COMBO]: [CreativeType.COMBO, CreativeType.HTML5],
  [AdType.RETAIL]: [CreativeType.RETAIL_RICH_MEDIA_PRODUCT, CreativeType.RETAIL_NATIVE_PRODUCT],
  [AdType.PILOT_TV]: [CreativeType.PILOT_TV],
  [AdType.CTV]: [CreativeType.CTV],
  [AdType.HAMI_VIDEO_DISPLAY]: [CreativeType.HAMI_VIDEO_NATIVE, CreativeType.HAMI_VIDEO_HTML, CreativeType.HAMI_VIDEO_IMAGE],
  [AdType.HAMI_VIDEO_VIDEO]: [CreativeType.HAMI_VIDEO_VIDEO],
  [AdType.HAMI_VIDEO_COMBO]: [CreativeType.HAMI_VIDEO_COMBO],
  [AdType.HAMI_VIDEO_CONNECTED_TV_IMAGE]: [CreativeType.HAMI_VIDEO_CONNECTED_TV_IMAGE],
  [AdType.HAMI_VIDEO_THIRD_PARTY_RECTANGLE]: [CreativeType.HAMI_VIDEO_THIRD_PARTY],
  [AdType.SANDBOX_OUTDOOR]: [CreativeType.SANDBOX_OUTDOOR]
};

export const RTBCAMPAIGN_DEFAULT_AGE_MIN = 13;
export const RTBCAMPAIGN_DEFAULT_AGE_MAX = 65;

export type SpaceDevice = {
  spaceId: string;
  spaceName: string;
  spaceStatus: string;
  storeNum: string;
  // deviceIds: string[];
  address: string;
  longitude: number;
  latitude: number;
  // publisherDomain: string;
  // publisherId: string;
  outdoorImageFile: string[];
};

export const conversionTrackingTypeOptions = SelectOptionsUtils.createSelectOptionsFromEnum(
  TrackEvent, 'campaign.labels.convTrackEvent'
);
