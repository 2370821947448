import { CreativeListCommonColumns, commonColumnSetting, getColumnSetting } from './CreativeListCommonColumnData';
import { renderColumn } from 'components/TableColumn/TableColumn';
import { PPSCreativeListDataProvider } from './PPSCreativeListDataProvider';

enum COLUMNS {
  LAYOUT_ID = 'ppsLayoutId'
}

export class HamiVideoThirdPartyCreativeListDataProvider extends PPSCreativeListDataProvider {

  getColumns () {
    return [
      renderColumn(commonColumnSetting[CreativeListCommonColumns.NAME_ID], this.formatters.nameIdFormatter, this.formatters.nameIdHeaderFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.STATUS], this.formatters.stateFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.PREVIEW], this.formatters.previewFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.APPROVAL], this.formatters.approvalFormatter),
      renderColumn(getColumnSetting(COLUMNS.LAYOUT_ID), this.formatters.ppsLayoutFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.LIMITATION], this.formatters.limitationFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.EDITBTNS], this.formatters.floatingEditBtnsFormatter)
    ];
  }
}
