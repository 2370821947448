import { getDataProvider } from 'components/ReportTable/ReportDataProviderFactory';
import { ReportDimension, ReportGran, ReportType } from 'core/report/ReportData';
import i18n from 'i18n';
import { AbstactReportContentModel } from '../ReportContentModel';
import moment from 'moment-timezone';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import _ from 'lodash';

export class PerformanceReportModel extends AbstactReportContentModel {

  get title () {
    return i18n.t<string>('report.labels.performanceTitle');
  }

  get needSpecifyAgency () {
    return false;
  }

  get defaultReportType () {
    return ReportType.PERFORMANCE;
  }

  get defaultReportDimension () {
    return ReportDimension.DAY;
  }

  get defaultReportGran () {
    return ReportGran.DAY;
  }

  get validDimensions () {
    return [ReportDimension.HOUR, ...this.state.tableDimensions];
  }

  get reportTypes () {
    const availableReportTypes = [
      ReportType.PERFORMANCE
    ];
    if (
      this.isSysAdmin ||
      this.addonFeatureManager.isAnyFeatureEnable(
        ADDONFEATURE.CREATIVES.VIDEO,
        ADDONFEATURE.CREATIVES.THIRD_PARTY,
        ADDONFEATURE.CREATIVES.COMBO,
        ADDONFEATURE.CREATIVES.EDIMAX,
        ADDONFEATURE.CREATIVES.PIC_LONG,
        ADDONFEATURE.CREATIVES.PIC_SHORT,
        ADDONFEATURE.CREATIVES.SANDBOX_OUTDOOR,
        ADDONFEATURE.CREATIVES.HAMI_VIDEO_VIDEO,
        ADDONFEATURE.CREATIVES.HAMI_VIDEO_COMBO,
        ADDONFEATURE.CREATIVES.HAMI_VIDEO_THIRD_PARTY
      )
    ) {
      availableReportTypes.push(ReportType.VIDEO);
    }

    if (
      this.isSysAdmin ||
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CHANNEL.MESSAGE)
    ) {
      availableReportTypes.push(ReportType.MESSAGE);
    }

    return availableReportTypes
        .map(reportType => {
          return {
            label: i18n.t<string>(`report.labels.${reportType}Type`),
            value: reportType
          };
        });
  }

  get reportGrans () {
    return Object.values(ReportGran)
      .filter(reportGran => reportGran !== ReportGran.MONTH)
      .map(reportGran => {
        return {
          label: i18n.t<string>(`report.labels.${reportGran}`),
          value: reportGran
        };
      }
    );
  }

  download = async () => {
    const {
      tableColumnSettings,
      tableData
    } = this.state;
    const getHeader = (setting) => {
      return i18n.t<string>(setting.text);
    };
    const metricsMapI18n = tableColumnSettings.reduce((acc, setting) => {
      acc[setting.dataField] = getHeader(setting);
      return acc;
    }, {} as {[key: string]: string});
    const metricsI18nMapTotal = tableColumnSettings.reduce((acc, setting) => {
      acc[getHeader(setting)] = setting.footer;
      return acc;
    }, {});
    const metrics = _.values(metricsMapI18n);
    const excelDataList = tableData.reduce((excelData, rowData) => {
      // [POS-6674] AM 希望下載下來的數字跟訂單頁面下載下來的數字一樣，不過 UI 每一格有用成小數點第二位，所以驗算加總不會一樣。
      // 這邊就全部小數點位數都提供給使用者，確保加總後數資一樣
      const newRowData = {};
      Object.keys(rowData).filter(key => !!metricsMapI18n[key]).forEach(key => {
        newRowData[metricsMapI18n[key]] = rowData[key];
      });
      excelData.push(newRowData);
      return excelData;
    }, [] as {[key: string]: string}[]);
    excelDataList.push(metricsI18nMapTotal);
    const XLSXModule = await import(/* webpackChunkName: "xlsx" */ 'xlsx');
    const XLSX = XLSXModule.default;
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excelDataList, { header: metrics });
    XLSX.utils.book_append_sheet(wb, ws, 'report');
    XLSX.writeFile(wb, 'report.xlsx');
  }

  canFilterSelect = () => {
    return !this.isSysAdmin;
  }

  canFilterRemove = () => {
    return true;
  }

  initReportData = async () => {
    this.reportType = ReportType.PERFORMANCE;
    this.dataProvider = getDataProvider(this.reportType, this.queryDataWithFilter, this.onDateClick);
    this.dimension = ReportDimension.DAY;
    this.gran = ReportGran.DAY;
    this.filter = {};
    this.from = moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
    this.to = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
    this.searchString = '';
    this.selectedTagFilter = [];
    this.timezone = this.localeMeta ? this.localeMeta.timezone : '+08:00';
    this.setUpReportTableData();
  }

  onDateClick = async (date) => {
    this.from = moment(date).startOf(this.gran).format('YYYY-MM-DD HH:mm:ss');
    this.to = moment(date).endOf(this.gran).format('YYYY-MM-DD HH:mm:ss');
    if (this.gran === ReportGran.DAY) {
      this.gran = ReportGran.HOUR;
      this.dimension = ReportDimension.HOUR;
    }
    this.updateSearchPath(false);
  }
}
