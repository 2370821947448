import i18n from 'i18n';
import { EdiMaxCreativeListDataProvider } from './EdiMaxCreativeListDataProvider';

enum COLUMNS {
  OUTDOOR_TYPE = 'outdoorType',
  TEMPERATURE = 'temperature',
  DURATION = 'duration'
}

export class SandboxOutdoorCreativeListDataProvider extends EdiMaxCreativeListDataProvider {

  getColumns () {
    const columns = super.getColumns();
    const durationColumn = columns.find(column => column.dataField === COLUMNS.DURATION);
    if (durationColumn) {
      durationColumn.formatter = duration => `${duration} ${i18n.t<string>('common.units.seconds')}`;
    }
    return columns;
  }
}
