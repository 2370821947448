import React from 'react';
import { CreativeFormBasicData, CreativeFormData, FormContentModel } from './FlowSteps/SubSteps/FormContent/FormContentModel';
import { CreativeType } from 'core/creative/Creative';
import { CreativeSummaryModel } from './FlowSteps/SubSteps/SummaryContent/CreativeSummaryModel';
import { Advertiser } from 'core/advertiser/Advertiser';

export type CreativeSetupFlowDataContextType = {
  creative: any,
  initCreative: any,
  advertisers: Array<SelectOptions>,
  tenmaxCategories: Array<SelectOptions>,
  supportedCreativeType: Array<CreativeType>,
  enableAdLogo: boolean,
  forPmp: boolean,
  advertiser?: Advertiser,
  setCreative (creative: CreativeFormData): void,
  setFinishedRedirectData (data?: {
    pathname: string,
    state?: any
  }): void,
  getFormContentModelOfType (creativeType: CreativeType): FormContentModel | undefined,
  getSummaryModel (creativeBasicFormData: CreativeFormBasicData): CreativeSummaryModel;
};

export const CreativeSetupFlowDataContext = React.createContext<CreativeSetupFlowDataContextType>({
  initCreative: {},
  creative: {},
  advertisers: [],
  tenmaxCategories: [],
  supportedCreativeType: [],
  enableAdLogo: false,
  forPmp: false,
  setCreative: () => {
    // This is intentional
  },
  setFinishedRedirectData: () => {
    // This is intentional
  },
  getFormContentModelOfType: () => { return undefined; },
  getSummaryModel: () => {
    throw new Error('getSummaryModel is not implemented');
  }
});
