import {
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { AbstractEdiMaxFormModel } from './EdiMaxFormModel';
import { LimitationData } from 'core/limitation/Limitation';

export interface SandboxOutdoorFormModel extends RtbCampaignBasicFormModel {
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}

abstract class AbstractSandboxOutdoorFormModel
  extends AbstractEdiMaxFormModel
  implements SandboxOutdoorFormModel {
  override get campaignAdType (): AdType {
    return AdType.SANDBOX_OUTDOOR;
  }

  override getDefaultLimitations (): LimitationData {
    return {
      include: [],
      exclude: [],
      preferred: [],
      nonPreferred: [],
      other: [
        {
          op: 'inc',
          type: 'dealId',
          value: [{ label: 'demo_space', value: 'demo_space' }]
        }
      ]
    };
  }
}

export class EditSandboxOutdoorFormModel extends AbstractSandboxOutdoorFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
  canEditOptimize (): boolean {
    return false;
  }
}

export class CreateSandboxOutdoorFormModel extends AbstractSandboxOutdoorFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
