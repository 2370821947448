import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { ColumnDefinition, renderColumn, SortDescriptor } from 'components/TableColumn/TableColumn';
import { CreativeOfCampaign } from 'core/creative/Creative';
import styles from './CreativeList.module.scss';
import {
  DefaultCreativeManager,
  CreativeManager
} from 'core/creative/CreativeManager';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import moment from 'moment';
import _ from 'lodash';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { L1Object } from 'core/l1Object/L1Object';
import { PermissionItem } from 'core';
import { ColumnDescription, HeaderFormatter } from 'react-bootstrap-table-next';
import { CreativeListRow } from './CreativeListRow';
import { getPercentValue } from 'helper/MetricsHelper';
import { CreativeBindData } from 'core/rtbCampaign/CreativeBindData';
import { CreativeListFormatters } from './defaultListFormatters';

export interface CreativeListModel {
  readonly l1Object: L1Object;
  creativeList?: CreativeListRow[];
  readonly event: UpdateEventListener<CreativeListModel>;
  readonly state: CreativeListState;
  readonly noDataDescription: string;
  readonly handleOnSelect: (creativeId: number) => void;
  readonly handleOnSelectAll: () => void;
  readonly selectedCreatives: (number | undefined)[];
  readonly columnsToShow: string[];
  readonly closingReportEnabled: boolean;
  readonly columns: ColumnDefinition[];
  reviewCreative: (adx: string, creativeId: number) => void;
  goReportPage: (creative) => void;
  getEditPath: (creativeId: number) => string;
  getSummaryData: () => Partial<{
    [ K in keyof CreativeListRow ]: string | number
  }>;
}

export type CreativeListModelProps = {
  readonly model: CreativeListModel;
};

export type CreativeListState = {
  readonly loading: boolean;
  readonly redirectPath?: string;
};

export enum CreativeListColumns {
  ID = 'id',
  PREVIEW = 'creativePreview',
  STATUS = 'bindingState',
  LAYOUT = 'ppsLayoutId',
  PRODUCTSET = 'productSet',
  DELIVERY = 'effectiveStatus',
  RESULTS = 'results',
  VTR = 'vtr',
  VCTR = 'vctr',
  CTR = 'ctr',
  VIMPRES = 'vimps',
  IMPRES = 'imps',
  VIEW = 'view',
  CLICKS = 'clicks',
  UU = 'lifetimeUU',
  TYPE = 'type',
  APPROVALSTATUS = 'approvalStatus',
  TOOL = 'tool'
}

export type CreativeIDExtra = {
  handleOnSelect: (id: number) => void;
  modifyPermissionAware: PermissionItem;
};
export class DefaultCreativeListModel implements CreativeListModel {
  event: FireableUpdateEventListener<CreativeListModel>;
  creativeList?: CreativeListRow[];
  selectedCreatives: (number | undefined)[];
  onSelect: (creativeId: number) => void;
  onSelectAll: () => void;
  columnsToShow: string[];
  creativeManager: CreativeManager;
  loading: boolean;
  redirectPath?: string;
  closingReportEnabled: boolean;
  editPath: (creativeId: number) => string;
  isDraft: boolean;

  constructor (
    public l1Object: L1Object,
    campaignInfo: Pick<
      CreativeBindData,
      'id' | 'startDate' | 'endDate' | 'draftId' | 'adType'
    >,
    creativeList: CreativeOfCampaign[],
    selectedCreatives: (number | undefined)[],
    closingReportEnabled: boolean,
    handleOnSelect: (creativeId: number) => void,
    handleOnSelectAll: () => void,
    editPath: (creativeId) => string,
    private listFormatters: CreativeListFormatters,
    columnsToShow?: string[],
    private modifyPermissionAware?: PermissionItem,
    creativeManager: CreativeManager = new DefaultCreativeManager()
  ) {
    this.creativeList = this.getViewModelData(
      creativeList,
      campaignInfo,
      selectedCreatives
    );
    const adType = campaignInfo.adType;
    const useImpres = adType
      ? [
          AdType.VIDEO,
          AdType.HAMI_VIDEO_VIDEO,
          AdType.THIRD_PARTY_BOTTOM,
          AdType.THIRD_PARTY_RECTANGLE,
          AdType.HAMI_VIDEO_THIRD_PARTY_RECTANGLE,
          AdType.EDIMAX,
          AdType.PIC_SHORT,
          AdType.PIC_LONG,
          AdType.SANDBOX_OUTDOOR
        ].includes(adType)
      : false;
    const useVImpres = adType
      ? [
          AdType.DISPLAY,
          AdType.HAMI_VIDEO_DISPLAY,
          AdType.HAMI_VIDEO_CONNECTED_TV_IMAGE
        ].includes(adType)
      : false;
    // [POS-4475] l3ObjectDaily does not have view data
    const useView = false;
    this.event = new FireableUpdateEventListener<CreativeListModel>();
    this.selectedCreatives = selectedCreatives;
    this.onSelect = handleOnSelect;
    this.onSelectAll = handleOnSelectAll;
    const useViewColumns = useView
      ? [CreativeListColumns.VTR, CreativeListColumns.VIEW]
      : [];
    const useImpresColumns = useImpres
      ? [CreativeListColumns.CTR, CreativeListColumns.IMPRES]
      : [];
    const useVImpresColumns = useVImpres
      ? [CreativeListColumns.VCTR, CreativeListColumns.VIMPRES]
      : [];
    this.columnsToShow = columnsToShow
      ? columnsToShow
      : _.compact([
        CreativeListColumns.ID,
        CreativeListColumns.PREVIEW,
        CreativeListColumns.STATUS,
        CreativeListColumns.DELIVERY,
        CreativeListColumns.RESULTS,
        ...useViewColumns,
        ...useImpresColumns,
        ...useVImpresColumns,
        adType && ![AdType.VIDEO, AdType.HAMI_VIDEO_VIDEO].includes(adType) ? CreativeListColumns.CLICKS : undefined,
        CreativeListColumns.UU,
        CreativeListColumns.TYPE,
        CreativeListColumns.APPROVALSTATUS,
        CreativeListColumns.TOOL
      ]);
    this.creativeManager = creativeManager;
    this.loading = false;
    this.closingReportEnabled = closingReportEnabled;
    this.editPath = editPath;
    this.isDraft = !!campaignInfo.draftId;
  }

  get columns () {
    return [
      this.creativeIDColumn,
      this.creativePreviewColumn,
      this.approvalStatusColumn,
      this.creativeStatusColumn,
      this.creativeDeliveryColumn,
      this.creativeLayoutColumn,
      this.creativeProductSetColumn,
      this.resultsColumn,
      this.vtrColumn,
      this.vctrColumn,
      this.ctrColumn,
      this.vimpsColumn,
      this.impsColumn,
      this.viewColumn,
      this.clicksColumn,
      this.uuColumn,
      this.typeAndSizeColumn,
      this.toolColumn
    ].filter(column => this.columnsToShow.indexOf(column.dataField) > -1);
  }

  handleOnSelect = (id: number) => {
    this.onSelect(id);
    this.updateState();
  }

  handleOnSelectAll = () => {
    this.onSelectAll();
    this.updateState();
  }

  getEditPath (creativeId: number) {
    return this.editPath(creativeId);
  }

  getViewModelData (
    creativeList: CreativeOfCampaign[] | undefined,
    campaignInfo,
    selectedCreatives
  ): CreativeListRow[] {
    if (!creativeList) {
      return [];
    }
    return creativeList.map(creative => {
      return {
        ...creative,
        results: _.get(creative, 'report.results', 0),
        lifetimeUU: _.get(creative, 'report.lifetimeUU', 0),
        creativeId: creative.id,
        campaignInfo: campaignInfo,
        selected: selectedCreatives
          ? selectedCreatives.indexOf(creative.id) > -1
          : false
      };
    });
  }

  getSummaryData = () => {
    let clickSum = 0;
    let impresSum = 0;
    let vimpresSum = 0;
    let viewSum = 0;
    let vtr = '';
    let vctr = '';
    let ctr = '';
    if (this.creativeList !== undefined) {
      clickSum = this.creativeList.reduce<number>(
        (partial, creative) => partial + creative.clicks,
        0
      );
      impresSum = this.creativeList.reduce<number>(
        (partial, creative) => partial + creative.imps,
        0
      );
      vimpresSum = this.creativeList.reduce<number>(
        (partial, creative) => partial + creative.vimps,
        0
      );
      viewSum = this.creativeList.reduce<number>(
        (partial, creative) => partial + creative.view,
        0
      );
      if (impresSum !== 0) {
        ctr = getPercentValue(clickSum, impresSum);
        vtr = getPercentValue(viewSum, impresSum);
      }
      if (vimpresSum !== 0) {
        vctr = getPercentValue(clickSum, vimpresSum);
      }
    }

    return {
      vctr,
      vtr,
      ctr,
      imps: impresSum,
      vimps: vimpresSum,
      view: viewSum,
      clicks: clickSum
    };
  }

  reviewCreative = async (adx: string, creativeId: number) => {
    this.updateState(true);
    try {
      await this.creativeManager.reviewCreatives([creativeId], [adx]);
      toast.success(i18next.t<string>('creativeList.labels.reviewSuccess'));
      this.updateState(false);
    } catch (e) {
      toast.error(i18next.t<string>('creativeList.labels.reviewFailed'));
      this.updateState();
    }
  }

  get state (): CreativeListState {
    return {
      loading: this.loading,
      redirectPath: this.redirectPath
    };
  }

  get noDataDescription (): string {
    return 'campaignCreativeList.headers.noDataAvailable';
  }

  get creativeIDColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.creativeIdColumn;
    };
    const nameHeaderFormatter: HeaderFormatter<CreativeListRow> = _.partial(
      this.listFormatters.nameHeaderFormatter,
      this.creativeList ? this.creativeList.length : 0,
      this.selectedCreatives.length,
      this.modifyPermissionAware,
      this.handleOnSelectAll
    );
    return renderColumn<CreativeListRow, CreativeListRow['id'], CreativeIDExtra>(
      {
        dataField: 'id',
        text: 'campaignCreativeList.headers.creativeName',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter,
        formatExtraData: {
          handleOnSelect: this.handleOnSelect,
          modifyPermissionAware: this.modifyPermissionAware
        }
      },
      this.listFormatters.creatvieIDFormatter,
      nameHeaderFormatter
    );
  }

  get creativePreviewColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.preveiwColumn;
    };
    return renderColumn(
      {
        dataField: 'creativePreview',
        text: 'campaignCreativeList.headers.creativePreview',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.creativePreviewFormatter
    );
  }

  get creativeStatusColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.statusColumn;
    };
    return renderColumn(
      {
        dataField: 'bindingState',
        text: 'campaignCreativeList.headers.creativeStatus',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter,
        formatExtraData: {
          isDraft: this.isDraft
        }
      },
      this.listFormatters.stateFormatter
    );
  }

  get creativeLayoutColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.layoutColumn;
    };
    return renderColumn(
      {
        dataField: 'ppsLayoutId',
        text: 'campaignCreativeList.headers.creativeLayout',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.layoutFormatter
    );
  }

  get creativeProductSetColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.productSetColumn;
    };
    return renderColumn(
      {
        dataField: 'productSet',
        text: 'campaignCreativeList.headers.creativeProductSet',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.productSetFormatter
    );
  }

  get creativeDeliveryColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.deliveryColumn;
    };
    return renderColumn(
      {
        dataField: 'effectiveStatus',
        text: 'campaignCreativeList.headers.effectiveStatus',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter,
        formatExtraData: {
          isDraft: this.isDraft
        }
      },
      this.listFormatters.deliveryFormatter
    );
  }

  get vtrColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.vtrColumn;
    };
    return renderColumn(
      {
        dataField: 'vtr',
        text: 'campaignCreativeList.headers.vtr',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.percentageFormatter
    );
  }

  get vctrColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.ctrColumn;
    };
    return renderColumn(
      {
        dataField: 'vctr',
        text: 'campaignCreativeList.headers.vctr',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.percentageFormatter
    );
  }

  get ctrColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.ctrColumn;
    };
    return renderColumn(
      {
        dataField: 'ctr',
        text: 'campaignCreativeList.headers.ctr',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.percentageFormatter
    );
  }

  get vimpsColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.impsColumn;
    };
    return renderColumn(
      {
        dataField: 'vimps',
        text: 'campaignCreativeList.headers.vimps',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.numberFormatter
    );
  }

  get impsColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.impsColumn;
    };
    return renderColumn(
      {
        dataField: 'imps',
        text: 'campaignCreativeList.headers.imps',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.numberFormatter
    );
  }

  get resultsColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.resultsColumn;
    };
    return renderColumn(
      {
        dataField: 'results',
        text: 'campaignCreativeList.headers.results',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter,
        formatExtraData: {
          l1Object: this.l1Object
        }
      },
      this.listFormatters.resultsFormatter
    );
  }

  get viewColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.viewColumn;
    };
    return renderColumn(
      {
        dataField: 'view',
        text: 'campaignCreativeList.headers.view',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.numberFormatter
    );
  }

  get clicksColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.clicksColumn;
    };
    return renderColumn(
      {
        dataField: 'clicks',
        text: 'campaignCreativeList.headers.clicks',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter
      },
      this.listFormatters.numberFormatter
    );
  }

  get uuColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.uuColumn;
    };
    return renderColumn(
      {
        dataField: 'lifetimeUU',
        text: 'campaignCreativeList.headers.uu',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter,
        formatExtraData: {
          channel: _.gt(this.l1Object, 'channel')
        }
      },
      this.listFormatters.uuFormatter
    );
  }

  get typeAndSizeColumn (): ColumnDescription {
    return renderColumn(
      {
        dataField: 'type',
        text: 'campaignCreativeList.headers.typeAndSize',
        sort: false
      },
      this.listFormatters.typeAndSizeFormatter
    );
  }

  get approvalStatusColumn (): ColumnDescription {
    const columnClassGetter = () => {
      return styles.approvalStatusColumn;
    };
    return renderColumn(
      {
        dataField: 'approvalStatus',
        text: 'campaignCreativeList.headers.approvalStatus',
        sort: false,
        classes: columnClassGetter,
        headerClasses: columnClassGetter,
        formatExtraData: {
          isDraft: this.isDraft,
          reviewCreative: this.reviewCreative
        }
      },
      this.listFormatters.approvalFormatter
    );
  }

  get toolColumn (): ColumnDescription {
    return renderColumn(
      {
        dataField: 'tool',
        text: '',
        sort: false,
        classes: 'editCreativeArea',
        formatExtraData: {
          goReportPage: this.goReportPage.bind(this),
          getEditPath: this.getEditPath.bind(this),
          l1Object: this.l1Object ? this.l1Object : { isSelfServe: true }
        }
      },
      this.listFormatters.floatingEditBtnsFormatter
    );
  }

  get defaultSorts (): SortDescriptor {
    return [
      {
        dataField: 'creativeNumber',
        order: 'asc'
      }
    ];
  }

  goReportPage (creative: CreativeListRow) {
    const campaignInfo = creative.campaignInfo;
    const from = encodeURIComponent(
      moment(campaignInfo.startDate)
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
    );
    const to = encodeURIComponent(
      moment(campaignInfo.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
    );
    this.redirectPath = `/reports/performance?dimension=l3ChannelId&from=${from}&to=${to}&l2ChannelId=${campaignInfo.id}&l3ChannelId=${creative.l3ChannelId}`;
    this.updateState(false);
  }

  updateState (loading: boolean = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
