import { L1Object, L1ObjectChannel, L1ObjectState } from 'core/l1Object/L1Object';

export abstract class DefaultDraftSetupStateContentModel {

  canGoNext: boolean = false;

  l1ObjectOptionsCache = {};

  constructor (public orderOptions: SelectOptions[]) {}

  abstract getL1ObjectsOfChannel (l1Objects);

  setCanGoNext = (canGoNext: boolean) => {
    this.canGoNext = canGoNext;
  }
}

export class RtbCampaignDraftSetupStateContentModel extends DefaultDraftSetupStateContentModel {
  getL1ObjectsOfChannel (l1Objects) {
    return l1Objects.filter(l1Object => l1Object.channel === L1ObjectChannel.RTB && l1Object.state !== L1ObjectState.DELETE);
  }
}

export class FbAdSetDraftSetupStateContentModel extends DefaultDraftSetupStateContentModel {
  getL1ObjectsOfChannel (l1Objects) {
    return l1Objects.filter(l1Object => l1Object.channel === L1ObjectChannel.FB && l1Object.state !== L1ObjectState.DELETE);
  }
}

export class RetailCampaignDraftSetupStateContentModel extends DefaultDraftSetupStateContentModel {
  getL1ObjectsOfChannel (l1Objects) {
    return l1Objects.filter(l1Object => l1Object.channel === L1ObjectChannel.RETAIL_MEDIA && l1Object.state !== L1ObjectState.DELETE);
  }
}

export class EdiMaxCampaignDraftSetupStateContentModel extends DefaultDraftSetupStateContentModel {
  getL1ObjectsOfChannel (l1Objects: any) {
    return l1Objects.filter(l1Object => l1Object.channel === L1ObjectChannel.EDIMAX && l1Object.state !== L1ObjectState.DELETE);
  }
}

export class PICCampaignDraftSetupStateContentModel extends DefaultDraftSetupStateContentModel {
  getL1ObjectsOfChannel (l1Objects: L1Object[]) {
    return l1Objects.filter(l1Object => l1Object.channel === L1ObjectChannel.PIC && l1Object.state !== L1ObjectState.DELETE);
  }
}

export class HamiVideoCampaignDraftSetupStateContentModel extends DefaultDraftSetupStateContentModel {
  getL1ObjectsOfChannel (l1Objects: any) {
    return l1Objects.filter(l1Object => l1Object.channel === L1ObjectChannel.HAMI_VIDEO && l1Object.state !== L1ObjectState.DELETE);
  }
}
