import React from 'react';
import styles from './rtbCampaignSummaryStep.module.scss';
import { RtbCampaignSummaryStepProps, RtbCampaignSummaryStepState } from './RtbCampaignSummaryStepModel';
import { Button, Tab, Tabs, Tooltip } from 'react-bootstrap';
import i18n from 'i18n';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { TAInfoCard } from 'components/TAInfoCard/TAInfoCard';
import _ from 'lodash';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { alertMessage } from 'components/AlertDialog';
import { withOverlayHoverTip } from 'hoc/withOverlayHoverTip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { LegacyEstimateViewModelData } from 'core/rtbCampaign/RtbCampaignManager';
import { FlowMediaSummarySection, FlowSummarySection } from 'components/FlowSummarySection/FlowSummarySection';
import { CreativeSummaryStepState } from 'containers/Creatives/CreativeSetupFlow/FlowSteps/CreativeSummaryStepModel';

enum CampaignSummaryTab {
  CAMPAIGN,
  CREATIVE
}

export class RtbCampaignSummaryStep extends React.Component<
  RtbCampaignSummaryStepProps,
  RtbCampaignSummaryStepState
> {
  handler?: number;

  initModel () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.initCreativeSummaryModel();
    this.props.model.getLegacyEstimateData();
  }

  componentDidMount () {
    this.initModel();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model === this.props.model) {
      return;
    }

    prevProps.model.unmount(this.handler);
    this.initModel();
  }

  componentWillUnmount () {
    this.props.model.unmount(this.handler);
  }

  renderLegacyEstimateData () {
    const data = this.props.model.state.legacyEstimateData;
    const onShowAddSaveTargetingModal =
      this.props.model.onShowAddSaveTargetingModal;
    return (
      <div className={styles.estimateData}>
        {data ? (
          <>
            {this.renderLegacyEstimateDataOfField('uniqueUser', data)}
            {this.renderLegacyEstimateDataOfField('impression', data)}
            {data.involvedLimitatinoTypes.length > 0 && (
              <div className={styles.estimateLimitation}>
                <div className={styles.estimateLimitationDesc}>
                  {i18n.t<string>('campaignSummary.labels.estimateDesc')}
                </div>
                {this.renderLegacyEstimateLimitations(
                  data.involvedLimitatinoTypes
                )}
              </div>
            )}
          </>
        ) : (
          <div className={styles.estimateLimitationDesc}>
            {this.props.model.noLegacyEstimateDes}
          </div>
        )}
        <>
          <div className={styles.saveHint}>
            {i18n.t<string>('taInfoCard.labels.saveTargeting')}
          </div>
          <button
            className={'btn btn-secondary btn-sm'}
            onClick={_.partial(onShowAddSaveTargetingModal, true)}
          >
            {i18n.t<string>('taInfoCard.buttons.saveTargeting')}
          </button>
        </>
      </div>
    );
  }

  renderLegacyEstimateDataOfField (
    field: string,
    legacyEstimateData: LegacyEstimateViewModelData
  ) {
    const InformationButtion = withOverlayHoverTip(
      FontAwesomeIcon,
      <Tooltip id={field}>
        {i18n.t<string>(`campaignSummary.labels.${field}Information`)}
      </Tooltip>
    );
    const data = legacyEstimateData[field];
    return (
      <div className={styles.estimateDataField}>
        <div className={styles.estimateDataTitle}>
          {i18n.t<string>(`campaignSummary.labels.${field}Title`)}
          <InformationButtion icon={faInfoCircle} placement='right' />
        </div>
        <div className={styles.estimateDataContent}>
          {data >= 50 ? (
            data
          ) : (
            <div className={styles.estimateLowHint}>
              <div>{'<50'}</div>
              <div>
                {i18n.t<string>('campaignSummary.labels.estimateLowHint')}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderLegacyEstimateLimitations (involvedLimitatinoTypes: string[]) {
    return (
      <ul>
        {involvedLimitatinoTypes.map(type => (
          <li key={type}>{i18n.t<string>(`limitation.labels.${type}`)}</li>
        ))}
      </ul>
    );
  }

  renderNormalCampaignSummary () {
    const model = this.props.model;
    const estimatedAudience = model.estimatedAudience;
    const draftBindingSummary = model.getCreativeBindingSummaryData();
    const renderEstimatedAudience = () => (
      <TAInfoCard
        orderType={model.order.orderType}
        channel={_.get(model.l1Object, 'channel', L1ObjectChannel.RTB)}
        advertiserId={model.order.advertiserId}
        appliedSavedTAInfo={model.appliedSavedTAInfo}
        targetingValue={model.targetingValue}
        estimatedAudience={estimatedAudience}
        audienceLowestThreshold={model.audienceLowestThreshold}
        savedTargetingList={model.savedTargetingList}
        showAddSaveTargetingModal={model.state.showAddSaveTargetingModal}
        onShowAddSaveTargetingModal={model.onShowAddSaveTargetingModal}
      />
    );
    const limitationsSummaryData = model.getLimitationsSummaryData();
    const trackingSummaryData = model.getTrackingSummaryData();
    return (
      <>
        <FlowSummarySection {...model.getAdTypeSummaryData()}/>
        <div className={styles.sepLine} />
        <div className={styles.basicSummary}>
          <FlowSummarySection {...model.getSetUpSummaryData()}/>
          {model.showLegacyEstimation && this.renderLegacyEstimateData()}
        </div>
        {
          !_.isEmpty(limitationsSummaryData.summaryData) &&
            <>
              <div className={styles.sepLine} />
              <FlowSummarySection
                {...model.getLimitationsSummaryData()}
                renderOtherInfo={renderEstimatedAudience}
              />
            </>
        }
        {model.flowModel.needSetupTracking
          && trackingSummaryData
          && !_.isEmpty(trackingSummaryData.summaryData)
          && (
            <>
              <div className={styles.sepLine} />
              <FlowSummarySection {...trackingSummaryData}/>
            </>
          )
        }
        {draftBindingSummary
          && !_.isEmpty(draftBindingSummary.summaryData)
          && (
            <>
              <div className={styles.sepLine} />
              <FlowSummarySection {...draftBindingSummary}/>
            </>
          )
        }
      </>
    );
  }

  renderCampaignSummary () {
    const model = this.props.model;
    const pmpSummaryData = model.getPmpSummaryData();
    return (
      <>
        <div className={styles.title}>
          {i18n.t<string>('campaignSummary.titles.checkYourSettings')}
        </div>
        <div className={styles.content}>
          {pmpSummaryData
            ? <FlowSummarySection {...pmpSummaryData}/>
            : this.renderNormalCampaignSummary()
          }
        </div>
      </>
    );
  }

  renderCreativeSummary (creativeSummaryStepState: CreativeSummaryStepState) {
    const {
      basicSummaryData,
      mediaSummaryData
    } = creativeSummaryStepState;

    return (
      <>
        <div className={styles.title}>
          {i18n.t<string>('campaignSummary.titles.checkYourSettings')}
        </div>
        <div className={styles.content}>
          {basicSummaryData &&
            <FlowSummarySection
              {...basicSummaryData}
            />
          }
          {mediaSummaryData &&
            <FlowMediaSummarySection
              {...mediaSummaryData}
            />
          }
        </div>
      </>
    );
  }

  onTabSelect = (tab: string | null) => {
    if (tab === null) {
      return;
    }
    this.props.model.goSubStep(+tab);
  }

  render () {
    const model = this.props.model;
    const objectType = model.flowModel.objectType;
    const onSaveDraft = model.flowModel.onSaveDraft;
    const hasCreative = !!model.creativeSummaryStepModel;
    const errors = model.flowModel.isPmp
      ? model.getPmpErrors()
      : undefined;
    const hasError = !_.isEmpty(errors);
    return (
      <div className={styles.container}>
        {model.state.loading && <LoadingIndicator />}
        {model.state.showPublishBindingFailed &&
          alertMessage(
            i18n.t<string>('common.warning'),
            i18n.t<string>('campaignSetupFlow.messages.publishBindingFailed'),
            model.redirectToLastPage
          )}
        {hasCreative ? (
          <Tabs
            id='campaign-summary-tab'
            activeKey={model.subStepIndex}
            onSelect={this.onTabSelect}
          >
            <Tab
              eventKey={CampaignSummaryTab.CAMPAIGN}
              title={i18n.t<string>(
                'stepSideBar.labels.campaignSettingSummary'
              )}
            >
              {this.renderCampaignSummary()}
            </Tab>
            {model.state.creativeSummaryStepState && (
              <Tab
                eventKey={CampaignSummaryTab.CREATIVE}
                title={i18n.t<string>(
                  'stepSideBar.labels.creativeSettingSummary'
                )}
              >
                {this.renderCreativeSummary(
                  model.state.creativeSummaryStepState
                )}
              </Tab>
            )}
          </Tabs>
        ) : (
          this.renderCampaignSummary()
        )}
        <div className={styles.buttonArea}>
          {hasCreative ? (
            model.subStepIndex === CampaignSummaryTab.CREATIVE ? (
              <Button
                disabled={hasError}
                variant='primary'
                style={{ marginRight: '20px' }}
                size='sm'
                onClick={model.submit}
              >
                {i18n.t<string>('common.buttons.submit')}
              </Button>
            ) : (
              <Button
                disabled={hasError}
                variant='primary'
                style={{ marginRight: '20px' }}
                size='sm'
                onClick={_.partial(
                  model.goSubStep,
                  CampaignSummaryTab.CREATIVE
                )}
              >
                {i18n.t<string>('common.buttons.nextPage')}
              </Button>
            )
          ) : (
            <>
              <Button variant='primary' size='sm' onClick={model.submit}>
                {objectType === 'campaign'
                  ? i18n.t<string>('common.buttons.submit')
                  : i18n.t<string>('common.buttons.publish')}
              </Button>
              {onSaveDraft && (
                <Button
                  variant='secondary'
                  size='sm'
                  onClick={_.partial(
                    onSaveDraft,
                    model.campaign
                  )}
                >
                  {i18n.t<string>('campaign.buttons.saveDraft')}
                </Button>
              )}
            </>
          )}
          <Button variant='secondary' size='sm' onClick={model.goLast}>
            {i18n.t<string>('campaign.buttons.back')}
          </Button>
        </div>
      </div>
    );
  }
}
