import { BidPriceSetting } from 'components/BidPrice/BidPriceSetting';
import { CustomField } from 'components/common/form/field/CustomField';
import { FormikField } from 'components/common/form/field/FormikField';
import { CHANNEL, CREATIVES } from 'core/agency/AddonFeature';
import { AD_TYPE_MAP_CREATIVE_TYPE, AdType } from 'core/rtbCampaign/RtbCampaign';
import { FieldArray, connect } from 'formik';
import i18next from 'i18n';
import _ from 'lodash';
import { FunctionComponent, useCallback, useMemo } from 'react';
import styles from './agencyForm.module.scss';
import { GROUP_CHANNEL_CAMPAIGN_TYPE_MAP } from 'core/l1Object/L1Object';

const creativeAdTypesMapping = Object.keys(AD_TYPE_MAP_CREATIVE_TYPE).reduce((mapping, adType) => {
  const allowCreativeTypes = AD_TYPE_MAP_CREATIVE_TYPE[adType];
  _.forEach(allowCreativeTypes, (creativeType) => {
    const creative = `option_${creativeType}`;
    mapping[creative] = mapping[creative] ? [
      ...mapping[creative],
      adType
    ] : [adType];
  });
  return mapping;
}, {} as {[key in CREATIVES]: string[]});

const AgencyAddonForm: FunctionComponent<any> = (props) => {
  const language = i18next.language;
  const creativeCampaignTypeInfoMap = useMemo(() => Object.keys(creativeAdTypesMapping).reduce((acc, creative) => {
    const adTypes = creativeAdTypesMapping[creative];
    const campaignTypes = adTypes.map(adType => i18next.t<string>(`agency.addonItem.creatives.adType_${adType.toLowerCase()}`));
    return {
      ...acc,
      [creative]: (
        <div key={language} className={styles.campaignTypesInfo}>
          {campaignTypes.join(', ')}
        </div>
      )
    };
  }, {} as {[key in CREATIVES]: any}), [language]);

  const {
    addonFeatures,
    companyName
  } = props.formik.values;
  const channelAddons = addonFeatures.channel;
  const setFieldValue = props.formik.setFieldValue;
  const setValues = props.formik.setValues;
  const onOrderAgcProfitSettingChange = useCallback((open) => {
    setFieldValue('addonFeatures.company.agencyProfit', open);
  }, [setFieldValue]);

  const onRevenueReportChange = useCallback((open) => {
    if (!open) {
      setValues(values => ({
        ...values,
        addonFeatures: {
          ...values.addonFeatures,
          company: {
            ...values.addonFeatures.company,
            rebate: false,
            useAgencyProfitOnRebateReport: false,
            showOutComeOnRebateReport: false
          }
        }
      }));
    }
  }, [setValues]);

  const onAgencySegmentAddonChange = useCallback((open) => {
    if (open) {
      setFieldValue('addonProps.agencySegmentLimitationName', companyName);
      return;
    }
    setFieldValue('addonProps.agencySegmentLimitationName', '');
  }, [companyName, setFieldValue]);

  const onPICChannelAddonChange = useCallback((open) => {
    setFieldValue('addonProps.picMargin', open ? 25 : undefined);
  }, [setFieldValue]);

  const onEdiMaxChannelAddonChange = useCallback((open) => {
    setFieldValue('addonProps.edimaxMargin', open ? 60 : undefined);
  }, [setFieldValue]);

  const renderBidPriceSettings = useCallback(({ form, name }) => {
    const allowedAdType = Object.keys(GROUP_CHANNEL_CAMPAIGN_TYPE_MAP).reduce((acc, l1ObjectChannel) => {
      if (channelAddons[CHANNEL[l1ObjectChannel]]) {
        return [
          ...acc,
          ...GROUP_CHANNEL_CAMPAIGN_TYPE_MAP[l1ObjectChannel]
        ];
      }
      return acc;
    }, [] as AdType[]);
    const currency = _.get(form.values, 'currency');
    const bidPriceSettings = _.get(form.values, name);
    const priceSettings = bidPriceSettings.map((bidPriceSetting, index) => {
      const type = bidPriceSetting.type;
      const optimizes = Object.keys(bidPriceSetting.autoBidCap);
      const showDetail = allowedAdType.includes(type);
      return (
        <BidPriceSetting
          key={type + showDetail}
          fieldName={`${name}.${index}`}
          type={type}
          currency={currency}
          optimizes={optimizes}
          showDetail={showDetail}
        />
      );
    });
    return (
      <div className={styles.bidPriceSettings}>
        {priceSettings}
      </div>
    );
  }, [channelAddons]);

  const renderBidPriceFieldArray = useCallback(() => (
    <FieldArray
      name='addonProps.campaignBidPrice'
      render={renderBidPriceSettings}
    />
  ), [renderBidPriceSettings]);

  return (
    <div className={styles.agencyFrom}>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.addonGroup.channel')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.channel.openRTB')}
            name='addonFeatures.channel.openRTB'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.channel.retail_media')}
            name='addonFeatures.channel.retail_media'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.channel.ediMax')}
            name='addonFeatures.channel.ediMax'
            onChange={onEdiMaxChannelAddonChange}
          />
          <CustomField label=''>
            <FormikField.InputGroup
              label={i18next.t<string>('agency.addonItem.channel.salesChannelMargin')}
              formGroupClassName={`${styles.inputGroup} ${styles.addonPropsField}`}
              fieldContentWidth={240}
              name='addonProps.edimaxMargin'
              type='number'
              step={1}
              disabled={!_.get(addonFeatures, 'channel.ediMax')}
              postfix='%'
            />
          </CustomField>
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.channel.message')}
            name='addonFeatures.channel.message'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.channel.facebook')}
            name='addonFeatures.channel.facebook'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.channel.pic')}
            name='addonFeatures.channel.pic'
            onChange={onPICChannelAddonChange}
          />
          <CustomField label=''>
            <FormikField.InputGroup
              label={i18next.t<string>('agency.addonItem.channel.salesChannelMargin')}
              formGroupClassName={`${styles.inputGroup} ${styles.addonPropsField}`}
              fieldContentWidth={240}
              name='addonProps.picMargin'
              type='number'
              step={1}
              disabled={!_.get(addonFeatures, 'channel.pic')}
              postfix='%'
            />
          </CustomField>
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.channel.hami_video')}
            name='addonFeatures.channel.hami_video'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.addonGroup.company')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.company.advertiserViewSpentInReport')}
            name='addonFeatures.company.advertiserViewSpentInReport'
          />
          <FormikField.Switch
            label={i18next.t<string>(
              'agency.addonItem.company.singleOrderBudgetConstraint'
            )}
            name='addonFeatures.company.singleOrderBudgetConstraint'
          />
          <CustomField label=''>
            <FormikField.Input
              formGroupClassName={styles.addonPropsField}
              label={i18next.t<string>('agency.addonItem.company.orderBudgetMaximum')}
              name='addonProps.orderBudgetMaximum'
              fieldContentWidth='auto'
              disabled={!_.get(addonFeatures, 'company.singleOrderBudgetConstraint')}
            />
          </CustomField>
          <FormikField.Switch
            label={i18next.t<string>(
              'agency.addonItem.company.lookalikeAudienceCreation'
            )}
            name='addonFeatures.company.lookalikeAudienceCreation'
          />
          <FormikField.Switch
            label={i18next.t<string>(
              'agency.addonItem.company.provideReportToWorkflow'
            )}
            name='addonFeatures.company.provideReportToWorkflow'
            onChange={onRevenueReportChange}
          />
          <FormikField.Switch
            label={i18next.t('agency.addonItem.company.agencyProfit')}
            name='addonFeatures.company.agencyProfit'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.company.rebate')}
            name='addonFeatures.company.rebate'
            disabled={!_.get(addonFeatures, 'company.provideReportToWorkflow')}
            hint={i18next.t<string>('agency.addonItem.dependenceHint', {
              addon: `${i18next.t<string>('agency.addonGroup.company')} > ${i18next.t<string>('agency.addonItem.company.provideReportToWorkflow')}`
            })}
          />
          <FormikField.Switch
            label={i18next.t<string>(
              'agency.addonItem.company.useAgencyProfitOnRebateReport'
            )}
            name='addonFeatures.company.useAgencyProfitOnRebateReport'
            disabled={!_.get(addonFeatures, 'company.provideReportToWorkflow')}
            hint={i18next.t<string>('agency.addonItem.dependenceHint', {
              addon: `${i18next.t<string>('agency.addonGroup.company')} > ${i18next.t<string>('agency.addonItem.company.provideReportToWorkflow')}`
            })}
          />
          <FormikField.Switch
            label={i18next.t<string>(
              'agency.addonItem.company.showOutComeOnRebateReport'
            )}
            name='addonFeatures.company.showOutComeOnRebateReport'
            disabled={!_.get(addonFeatures, 'company.provideReportToWorkflow')}
            hint={i18next.t<string>('agency.addonItem.dependenceHint', {
              addon: `${i18next.t<string>('agency.addonGroup.company')} > ${i18next.t<string>('agency.addonItem.company.provideReportToWorkflow')}`
            })}
          />
          {/* <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.company.creditLimit')}
            name='addonFeatures.company.creditLimit'
          /> */}
          {/* <CustomField label=''>
            <FormikField.InputGroup
              label={i18next.t<string>('agency.addonItem.company.creditLimitMaximum')}
              formGroupClassName={styles.addonPropsField}
              name='addonProps.creditLimit'
              fieldContentWidth='auto'
              disabled={!_.get(addonFeatures, 'company.creditLimit')}
              prefix={currency}
              className={styles.creditLimitMaximum}
            />
          </CustomField> */}
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.company.allowOrderSettlement')}
            name='addonFeatures.company.allowOrderSettlement'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.company.allowOrderAutoSettlement')}
            name='addonFeatures.company.allowOrderAutoSettlement'
            disabled={!_.get(addonFeatures, 'company.allowOrderSettlement')}
            hint={i18next.t<string>('agency.addonItem.dependenceHint', {
              addon: `${i18next.t<string>('agency.addonGroup.company')} > ${i18next.t<string>('agency.addonItem.company.allowOrderSettlement')}`
            })}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.company.modifyOrderBudgetAfterSettlement')}
            name='addonFeatures.company.modifyOrderBudgetAfterSettlement'
            disabled={!_.get(addonFeatures, 'company.allowOrderSettlement')}
            hint={i18next.t<string>('agency.addonItem.dependenceHint', {
              addon: `${i18next.t<string>('agency.addonGroup.company')} > ${i18next.t<string>('agency.addonItem.company.allowOrderSettlement')}`
            })}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.company.fixedExchangeRate')}
            name='addonFeatures.company.fixedExchangeRate'
          />
          <CustomField label=''>
            <FormikField.Input
              formGroupClassName={styles.addonPropsField}
              label={i18next.t<string>('agency.addonItem.company.ExchangeRate')}
              name='addonProps.fixedExchangeRate'
              fieldContentWidth='auto'
              disabled={!_.get(addonFeatures, 'company.fixedExchangeRate')}
            />
          </CustomField>
          {/* <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.company.outdoorAgency')}
            name='addonFeatures.company.outdoorAgency'
          /> */}
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.company.storedValue')}
            name='addonFeatures.company.storedValue'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.company.agencySegment')}
            name='addonFeatures.company.agencySegment'
            onChange={onAgencySegmentAddonChange}
          />
          <CustomField label=''>
            <FormikField.Input
              formGroupClassName={styles.addonPropsField}
              label={i18next.t<string>('agency.addonItem.company.agencySegmentLimitationName')}
              name='addonProps.agencySegmentLimitationName'
              fieldContentWidth={500}
              disabled={!_.get(addonFeatures, 'company.agencySegment')}
              postText={i18next.t<string>('limitation.labels.agencySegment', { name: '' })}
            />
          </CustomField>
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.company.advAdminInfoRestriction')}
            name='addonFeatures.company.advAdminInfoRestriction'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.company.directlyApproveCreative')}
            name='addonFeatures.company.directlyApproveCreative'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.addonGroup.adsOrder')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.order.tenmax')}
            name='addonFeatures.order.tenmax'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.order.gojek')}
            name='addonFeatures.order.gojek'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.order.showOrderExternalType')}
            name='addonFeatures.order.showOrderExternalType'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.addonGroup.campaign')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.bid_weight_setting')}
            name='addonFeatures.campaign.bid_weight_setting'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.orderAgcProfitSetting')}
            name='addonFeatures.campaign.orderAgcProfitSetting'
            onChange={onOrderAgcProfitSettingChange}
          />
          <FormikField.Switch
            label={i18next.t<string>(
              'agency.addonItem.campaign.enableOptimizedPrice'
            )}
            name='addonFeatures.campaign.enableOptimizedPrice'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.fix_cpc')}
            name='addonFeatures.campaign.fix_cpc'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.fix_cpm')}
            name='addonFeatures.campaign.fix_cpm'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.fix_cpv')}
            name='addonFeatures.campaign.fix_cpv'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.dynamic_cpm')}
            name='addonFeatures.campaign.dynamic_cpm'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.fix_vcpm')}
            name='addonFeatures.campaign.fix_vcpm'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.revenueSharing')}
            name='addonFeatures.campaign.revenueSharing'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.budgetDominate')}
            name='addonFeatures.campaign.budgetDominate'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.videoAdViewObjective')}
            name='addonFeatures.campaign.videoAdViewObjective'
          />
          <CustomField
            label={i18next.t<string>('agency.addonItem.campaign.bidPriceConstraint')}
            name='campaignBidPrice'
            render={renderBidPriceFieldArray}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.thirdparty_tracking')}
            name='addonFeatures.campaign.thirdparty_tracking'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaign.retargeting_tracker')}
            name='addonFeatures.campaign.retargeting_tracker'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.addonGroup.creatives')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_image')}
            name='addonFeatures.creatives.option_1'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.IMAGE]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_html5')}
            name='addonFeatures.creatives.option_2'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.HTML5]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_native')}
            name='addonFeatures.creatives.option_3'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.NATIVE]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_video')}
            name='addonFeatures.creatives.option_4'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.VIDEO]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_thirdParty')}
            name='addonFeatures.creatives.option_8'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.THIRD_PARTY]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_combo')}
            name='addonFeatures.creatives.option_10'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.COMBO]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_edimax')}
            name='addonFeatures.creatives.option_11'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.EDIMAX]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_picShort')}
            name={`addonFeatures.creatives.${CREATIVES.PIC_SHORT}`}
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.PIC_SHORT]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_picLong')}
            name={`addonFeatures.creatives.${CREATIVES.PIC_LONG}`}
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.PIC_LONG]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_oneForAllDisplay')}
            name='addonFeatures.creatives.option_13'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.ONE_FOR_ALL_DISPLAY]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_oneForAllVideo')}
            name='addonFeatures.creatives.option_16'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.ONE_FOR_ALL_VIDEO]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_retailRichMediaProduct')}
            name='addonFeatures.creatives.option_20'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.RETAIL_RICH_MEDIA_PRODUCT]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_retailNativeProduct')}
            name='addonFeatures.creatives.option_21'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.RETAIL_NATIVE_PRODUCT]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_pilotTv')}
            name='addonFeatures.creatives.option_22'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.PILOT_TV]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_ctv')}
            name='addonFeatures.creatives.option_23'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.CTV]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_customRectangle')}
            name='addonFeatures.creatives.option_24'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.CUSTOM_RECTANGLE]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_customBottom')}
            name='addonFeatures.creatives.option_25'
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.CUSTOM_BOTTOM]}
          />
          {/* <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_hamiVideoNative')}
            name={`addonFeatures.creatives.${CREATIVES.HAMI_VIDEO_NATIVE}`}
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.HAMI_VIDEO_NATIVE]}
          /> */}
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_hamiVideoImage')}
            name={`addonFeatures.creatives.${CREATIVES.HAMI_VIDEO_IMAGE}`}
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.HAMI_VIDEO_IMAGE]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_hamiVideoVideo')}
            name={`addonFeatures.creatives.${CREATIVES.HAMI_VIDEO_VIDEO}`}
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.HAMI_VIDEO_VIDEO]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_hamiVideoCombo')}
            name={`addonFeatures.creatives.${CREATIVES.HAMI_VIDEO_COMBO}`}
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.HAMI_VIDEO_COMBO]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_hamiVideoConnectedTvImage')}
            name={`addonFeatures.creatives.${CREATIVES.HAMI_VIDEO_CONNECTED_TV_IMAGE}`}
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.HAMI_VIDEO_CONNECTED_TV_IMAGE]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_hamiVideoThirdParty')}
            name={`addonFeatures.creatives.${CREATIVES.HAMI_VIDEO_THIRD_PARTY}`}
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.HAMI_VIDEO_THIRD_PARTY]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.option_sandboxOutdoor')}
            name={`addonFeatures.creatives.${CREATIVES.SANDBOX_OUTDOOR}`}
            postComponent={creativeCampaignTypeInfoMap[CREATIVES.SANDBOX_OUTDOOR]}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.creatives.customizeAdLogo')}
            name='addonFeatures.creatives.customizeAdLogo'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.gogan.addonGroup.campaignLimitation')} </span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.country')}
            name='addonFeatures.campaignLimitation.country'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.city')}
            name='addonFeatures.campaignLimitation.city'
            disabled={!_.get(addonFeatures, 'campaignLimitation.country')}
            hint={i18next.t<string>('agency.addonItem.dependenceHint', {
              addon: `${i18next.t<string>('agency.addonGroup.campaignLimitation')} > ${i18next.t<string>('agency.addonItem.campaignLimitation.country')}`
            })}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.spaceType')}
            name='addonFeatures.campaignLimitation.spaceType'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.device')}
            name='addonFeatures.campaignLimitation.device'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.gogan.addonItem.campaignLimitation.target_audience')}
            name='addonFeatures.campaignLimitation.target_audience'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.adexchange')}
            name='addonFeatures.campaignLimitation.adexchange'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.asiamax_space')}
            name='addonFeatures.campaignLimitation.asiamax_space'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.domain')}
            name='addonFeatures.campaignLimitation.domain'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.space_category')}
            name='addonFeatures.campaignLimitation.space_category'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.tenmax_audience')}
            name='addonFeatures.campaignLimitation.tenmax_audience'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.gender')}
            name='addonFeatures.campaignLimitation.gender'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.age')}
            name='addonFeatures.campaignLimitation.age'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.carrier')}
            name='addonFeatures.campaignLimitation.carrier'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.enablePMPDeal')}
            name='addonFeatures.campaignLimitation.enablePMPDeal'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.keyword')}
            name='addonFeatures.campaignLimitation.keyword'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.content_language')}
            name='addonFeatures.campaignLimitation.content_language'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.campaignLimitation.content_category')}
            name='addonFeatures.campaignLimitation.content_category'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.addonGroup.conversiontracking')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t<string>(
              'agency.addonItem.conversiontracking.TrackingList'
            )}
            name='addonFeatures.conversiontracking.TrackingList'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.addonGroup.report')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.report.viewableCtr')}
            name='addonFeatures.report.viewableCtr'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.report.closingReport')}
            name='addonFeatures.report.closingReport'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.report.restrictFinalReport')}
            name='addonFeatures.report.restrictFinalReport'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.report.orderCustom')}
            name='addonFeatures.report.orderCustom'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.report.campaignDashboard')}
            name='addonFeatures.report.campaignDashboard'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.addonGroup.monitor')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Switch
            label={i18next.t<string>(
              'agency.addonItem.monitor.campaignBehindProgress'
            )}
            name='addonFeatures.monitor.campaignBehindProgress'
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.addonItem.monitor.systemProfitMonitor')}
            name='addonFeatures.monitor.systemProfitMonitor'
          />
          <CustomField label=''>
            <FormikField.InputGroup
              label={i18next.t<string>('agency.addonItem.monitor.percentLabel')}
              formGroupClassName={`${styles.inputGroup} ${styles.addonPropsField}`}
              fieldContentWidth='auto'
              name='addonProps.sysProfitMonitorPercent'
              disabled={!_.get(addonFeatures, 'monitor.systemProfitMonitor')}
              postfix='%'
            />
          </CustomField>
        </div>
      </fieldset>
    </div>
  );
};

export default connect(AgencyAddonForm);
